.ant-picker-dropdown{
  .ant-picker-panels{
    background-color:var(--input-background);
    .ant-picker-header{
      button{
        span{
          color: var(--font-color-dark-1);
        }
      }
      .ant-picker-header-view{
        button{
          color: var(--font-color-dark-1);
        }
      }
    }
    .ant-picker-body{
      .ant-picker-content{
        tr{
          th{
            color: var(--font-color-dark-1);
          }
          td{
            color: var(--font-color-dark-1);
          }
        }
        .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
          background: var(--date-picker-range);
        }
        .ant-picker-cell-in-view.ant-picker-cell-range-end{
          &:before{
            background: var(--date-picker-range);
          }
        }
        .ant-picker-cell-in-view {
          &.ant-picker-cell-range-hover-end{
            &:before{
              //background: red;
            }
            .ant-picker-cell-inner{
              &:after{
                background: var(--secondary-color);
              }
            }

          }
        }
        .ant-picker-cell-in-view {
          &.ant-picker-cell-range-hover-start{
            &:before{
              //background: red;
            }
            .ant-picker-cell-inner{
              //background: red;
              &:after{
                background: var(--secondary-color);
              }
            }

          }
        }
        .ant-picker-cell-in-view.ant-picker-cell-range-start{
          &:before{
            background: var(--date-picker-range);
          }
        }
      }
    }
  }
}

.ant-picker-dropdown{
  .ant-picker-panel-container{
    background-color:var(--input-background);
    .ant-picker-header{
      button{
        span{
          color: var(--font-color-dark-1);
        }
      }
      .ant-picker-header-view{
        button{
          color: var(--font-color-dark-1);
        }
      }
    }
    .ant-picker-body{
      .ant-picker-content{
        tr{
          th{
            color: var(--font-color-dark-1);
          }
          td{
            color: var(--font-color-dark-1);
          }
        }
        .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
          background: var(--date-picker-range);
        }
        .ant-picker-cell-in-view.ant-picker-cell-range-end{
          &:before{
            background: var(--date-picker-range);
          }
        }
        .ant-picker-cell-in-view {
          &.ant-picker-cell-range-hover-end{
            &:before{
              //background: red;
            }
            .ant-picker-cell-inner{
              &:after{
                background: var(--secondary-color);
              }
            }

          }
        }
        .ant-picker-cell-in-view {
          &.ant-picker-cell-range-hover-start{
            &:before{
              //background: red;
            }
            .ant-picker-cell-inner{
              //background: red;
              &:after{
                background: var(--secondary-color);
              }
            }

          }
        }
        .ant-picker-cell-in-view.ant-picker-cell-range-start{
          &:before{
            background: var(--date-picker-range);
          }
        }
      }
    }
  }
}

.ant-picker-dropdown{
  .ant-picker-panel-container{
    .ant-picker-time-panel{
      .ant-picker-time-panel-column{
        border-inline-start: 1px solid var(--theme-border);
        .ant-picker-time-panel-cell{
          .ant-picker-time-panel-cell-inner{
            color: var(--font-color-dark-1);
          }
          &.ant-picker-time-panel-cell-selected{
            .ant-picker-time-panel-cell-inner{
              background: var(--secondary-color);
              color: var(--font-color-dark-1);
            }
          }
        }
        &::-webkit-scrollbar {
          display: block;
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #1677ff;
          width: 5px;
          //border-radius: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: #999;
        }
      }
    }
    .ant-picker-footer{
      border-top: 1px solid var(--theme-border);
      .ant-picker-ranges{
        .ant-btn-primary{
          &:disabled{
            color: var(--font-color-grey-1);
          }
        }
        .ant-picker-now-btn{
          color: var(--secondary-color);
        }
      }
    }
  }
}

//:where(.css-dev-only-do-not-override-1nndw4u).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, :where(.css-dev-only-do-not-override-1nndw4u).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
//  background: red;
//}