.about-us{
  display: flex;
  align-items: center;
}

.about-boxes{
  background: #C4EAE8;
  padding: 70px 0;
  .cards{
    padding-top:40px;
    .about-card{
      min-height:240px;
      border: 1px solid #BDBDBD;
      height: 400px;
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      padding: 15%;
      margin: 0 20px;
     span{
       margin-bottom: 30px;
     }
      h4{
        font-size: 38px;
        line-height: 43px;
        margin-bottom: 20px;
      }
      p{
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

.about-mission{
  padding: 70px 0;
  .mission-section{
    .mission-box{
      display: flex;
      align-items: center;
      gap: 50px;
      &:first-child{
        .content{
          border-bottom: 1px solid rgba(0,0,0,0.1);
        }
      }
      .head{
        flex: 1;
        min-width: 300px;
        max-width: 380px;
      }
      .content{
        max-width: 580px;
        padding: 60px 0;
        h4{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #000000;
        }
        p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 30px;
          color: #000000;
          margin-bottom: 0;
        }
      }
    }
  }
}

.about-values{
  padding: 100px 0;
  background: #C4EAE8;
  .heading-section{
    max-width: 750px;
    margin: auto;
    text-align: center;
  }
  .value-list-section{
    margin-top: 40px;
    ul{
      li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px solid #42424A;
        h4{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 26px;
          color: #42424A;
        }
        p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          color: #42424A;
          max-width: 800px;
        }
      }
    }
  }
}




@media screen and (min-width: 1600px) and (max-width: 1800px){
  .about-boxes{
    padding: 70px 0;
    .cards{
      padding-top:40px;
      .about-card{
        min-height:240px;
        height: 400px;
        padding: 15%;
        margin: 0 20px;
        span{
          margin-bottom: 30px;
        }
        h4{
          font-size: 38px;
          line-height: 43px;
          margin-bottom: 20px;
        }
        p{
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  .about-mission{
    padding: 70px 0;
    .mission-section{
      .mission-box{
        gap: 50px;
        .head{
          flex: 1;
          min-width: 300px;
          max-width: 380px;
          .heading{
            font-size: 48px;
          }
        }
        .content{
          max-width: 580px;
          padding: 60px 0;
          h4{
            font-size: 24px;
            line-height: 36px;
          }
          p{
            font-size: 17px;
            line-height: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .about-values{
    padding: 100px 0;
    .heading-section{
      max-width: 750px;
    }
    .value-list-section{
      margin-top: 40px;
      ul{
        li{
          padding: 20px 0;
          h4{
            font-size: 24px;
            line-height: 26px;
          }
          p{
            font-size: 18px;
            line-height: 30px;
            max-width: 800px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px){
  .about-boxes{
    padding: 60px 0;
    .cards{
      padding-top:10px;
      .about-card{
        min-height:240px;
        height: 400px;
        padding: 13%;
        margin: 0 20px;
        span{
          margin-bottom: 30px;
        }
        h4{
          font-size: 38px;
          line-height: 43px;
          margin-bottom: 20px;
        }
        p{
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  .about-mission{
    padding: 70px 0;
    .mission-section{
      .mission-box{
        gap: 40px;
        .head{
          flex: 1;
          min-width: 280px;
          max-width: 360px;
          .heading{
            font-size: 44px;
          }
        }
        .content{
          max-width: 580px;
          padding: 60px 0;
          h4{
            font-size: 22px;
            line-height: 36px;
          }
          p{
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .about-values{
    padding: 90px 0;
    .heading-section{
      max-width: 750px;
    }
    .value-list-section{
      margin-top: 40px;
      ul{
        li{
          padding: 20px 0;
          h4{
            font-size: 23px;
            line-height: 26px;
          }
          p{
            font-size: 18px;
            line-height: 30px;
            max-width: 800px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
  .about-boxes{
    padding: 60px 0;
    .cards{
      padding-top:10px;
      .about-card{
        min-height:200px;
        max-height: 340px;
        height: 400px;
        padding: 10%;
        margin: 0 15px;
        span{
          margin-bottom: 30px;
        }
        h4{
          font-size: 34px;
          line-height: 43px;
          margin-bottom: 20px;
        }
        p{
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  .about-mission{
    padding: 70px 0;
    .mission-section{
      .mission-box{
        gap: 30px;
        .head{
          flex: 1;
          min-width: 260px;
          max-width: 340px;
          .heading{
            font-size: 40px;
          }
        }
        .content{
          max-width: 520px;
          padding: 60px 0;
          h4{
            font-size: 22px;
            line-height: 36px;
          }
          p{
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .about-values{
    padding: 90px 0;
    .heading-section{
      max-width: 750px;
    }
    .value-list-section{
      margin-top: 40px;
      ul{
        li{
          padding: 20px 0;
          h4{
            font-size: 23px;
            line-height: 26px;
          }
          p{
            font-size: 18px;
            line-height: 30px;
            max-width: 800px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
  .about-boxes{
    padding: 60px 0;
    .cards{
      padding-top:10px;
      .about-card{
        min-height:200px;
        max-height: 310px;
        height: 400px;
        padding: 10%;
        margin: 0 15px;
        span{
          margin-bottom: 30px;
          svg{
            height: 80px !important;
            width: 80px !important;
          }
        }
        h4{
          font-size: 32px;
          line-height: 38px;
          margin-bottom: 15px;
        }
        p{
          font-size: 17px;
          line-height: 22px;
        }
      }
    }
  }

  .about-mission{
    padding: 60px 0;
    .mission-section{
      .mission-box{
        gap: 30px;
        .head{
          flex: 1;
          min-width: 260px;
          max-width: 340px;
          .heading{
            font-size: 40px;
          }
        }
        .content{
          max-width: 520px;
          padding: 40px 0;
          h4{
            font-size: 22px;
            line-height: 36px;
          }
          p{
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .about-values{
    padding: 80px 0;
    .heading-section{
      max-width: 700px;
    }
    .value-list-section{
      margin-top: 30px;
      ul{
        li{
          padding: 15px 0;
          h4{
            font-size: 22px;
            line-height: 26px;
          }
          p{
            font-size: 17px;
            line-height: 30px;
            max-width: 700px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px){
  .home-about{
    img{
      max-width: 400px;
    }
  }

  .about-boxes{
    padding: 60px 0;
    .cards{
      padding-top:10px;
      .about-card{
        min-height:200px;
        max-height: 310px;
        height: 400px;
        padding: 10%;
        margin: 0 15px;
        margin-bottom: 40px;
        span{
          margin-bottom: 30px;
        }
        h4{
          font-size: 34px;
          line-height: 42px;
          margin-bottom: 15px;
        }
        p{
          font-size: 19px;
          line-height: 22px;
        }
      }
    }
  }

  .about-mission{
    padding: 40px 0;
    .mission-section{
      .mission-box{
        gap: 30px;
        .head{
          flex: 1;
          min-width: 260px;
          max-width: 340px;
          .heading{
            font-size: 40px;
          }
        }
        .content{
          max-width: 520px;
          padding: 40px 0;
          h4{
            font-size: 22px;
            line-height: 36px;
          }
          p{
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .about-values{
    padding: 70px 0;
    .heading-section{
      max-width: 700px;
    }
    .value-list-section{
      margin-top: 30px;
      ul{
        li{
          padding: 15px 0;
          h4{
            font-size: 21px;
            line-height: 26px;
          }
          p{
            font-size: 17px;
            line-height: 30px;
            max-width: 520px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px){
  .home-about{
    img{
      max-width: 400px;
    }
  }

  .about-boxes{
    padding: 50px 0;
    .cards{
      padding-top:0;
      .about-card{
        min-height:200px;
        max-height: 310px;
        height: 400px;
        padding: 10%;
        margin: 0 15px;
        margin-bottom: 40px;
        span{
          margin-bottom: 30px;
        }
        h4{
          font-size: 34px;
          line-height: 42px;
          margin-bottom: 15px;
        }
        p{
          font-size: 19px;
          line-height: 22px;
        }
      }
    }
  }

  .about-mission{
    padding: 40px 0;
    .mission-section{
      .mission-box{
        gap: 20px;
        align-items: start;
        flex-direction: column;
        padding: 20px 0;
        &:first-child{
          .content{
            p{
              margin-bottom: 50px;
            }
          }
        }
        .head{
          flex: 1;
          min-width: 260px;
          max-width: 340px;
          .heading{
            font-size: 40px;
          }
        }
        .content{
          max-width: 520px;
          padding: 0;
          h4{
            font-size: 22px;
            line-height: 36px;
          }
          p{
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .about-values{
    padding: 60px 0;
    .heading-section{
      max-width: 700px;
    }
    .value-list-section{
      margin-top: 30px;
      ul{
        li{
          padding: 20px 0;
          flex-direction: column;
          align-items: start;
          h4{
            font-size: 20px;
            line-height: 26px;
          }
          p{
            font-size: 16px;
            line-height: 26px;
            max-width: 520px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px){
  .home-about{
    img{
      max-width: 340px;
    }
  }
  .about-boxes{
    padding: 50px 0;
    .cards{
      padding-top:0;
      .about-card{
        min-height:200px;
        max-height: 310px;
        height: 400px;
        padding: 10%;
        margin: 0 15px;
        margin-bottom: 40px;
        span{
          margin-bottom: 30px;
        }
        h4{
          font-size: 34px;
          line-height: 42px;
          margin-bottom: 15px;
        }
        p{
          font-size: 19px;
          line-height: 22px;
        }
      }
    }
  }

  .about-mission{
    padding: 30px 0;
    .mission-section{
      .mission-box{
        gap: 20px;
        align-items: start;
        flex-direction: column;
        padding: 30px 10px;
        &:first-child{
          .content{
            p{
              margin-bottom: 50px;
            }
          }
        }
        .head{
          flex: 1;
          min-width: 260px;
          max-width: 340px;
          .heading{
            font-size: 36px;
          }
        }
        .content{
          max-width: 520px;
          padding: 0;
          h4{
            font-size: 20px;
            line-height: 36px;
          }
          p{
            font-size: 15px;
            line-height: 30px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .about-values{
    padding: 50px 10px;
    .heading-section{
      max-width: 700px;
    }
    .value-list-section{
      margin-top: 30px;
      ul{
        li{
          padding: 20px 0;
          flex-direction: column;
          align-items: start;
          h4{
            font-size: 20px;
            line-height: 26px;
          }
          p{
            font-size: 16px;
            line-height: 26px;
            max-width: 520px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}