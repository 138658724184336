.theme-table{
  .ant-table-wrapper{
    .ant-table{
      background: var(--background);
      .ant-table-thead{
        tr{
          th{
            background: var(--background);
            color: var(--grey, #A8A9BA);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 166.667% */
            border-bottom: 1px solid var(--theme-border);
            &:before{
              background-color:var(--background) !important;
            }
          }
        }
      }
      .ant-table-tbody{
        tr{
          background: var(--background);
          color: var(--grey, #A8A9BA);
          border-bottom: none;
          &:hover{
            background: var(--background);
          }
          td{
            border-bottom: none;
            &.ant-table-cell-row-hover{
              background: var(--background);
            }
          }
        }
      }
    }
  }
}