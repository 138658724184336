.digital-page{
  p{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000000;
  }
  .dig-section-1{
    padding: 70px 0;
    h3{
      font-family: Poppins;
      font-size: 56px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -1px;
      text-align: left;
      margin-bottom: 30px;
    }
    h5{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
      margin-bottom: 30px;
    }
    p{
      font-family: Poppins;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;

    }
    img{
      max-width: 500px;
    }
  }
  .dig-section-2{
    background: #f3fbfa;
    padding: 70px 10px 50px 10px;
    .d-box{
      max-width: 480px;
      margin-bottom: 40px;
      img{
        max-width: 369px;
        height: 270px;
        margin-bottom: 25px;
      }
      h4{
        font-family: Poppins;
        font-size: 32px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -2px;
        text-align: left;
      }
      p{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
      }
    }
  }
  .dig-section-3{
    padding: 70px 0;
    img{
      max-width: 600px;
    }
  }
}