:root {
  //Changeable
  --primary-color:#20C7BB;
  --secondary-color:#40C9A2;
  --background: #212121;
  --background-2: #353535;
  --background-3:#0A0D2B;
  --stroke-color:var(--stroke-g2, linear-gradient(136deg, rgba(226, 100, 41, 0.50) 3.06%, rgba(247, 183, 72, 0.45) 30.88%, rgba(231, 179, 0, 0.06) 75.64%));
  --natural-dark:#2B2C39;
  --natural-dark-2:#2B2C39;
  --natural-light:#BEBEBE;
  --natural-secondary-1:#FFF;
  --natural-secondary-2:#FCFAED;
  --font-family: Cerebri Sans Pro;
  --background-gradient:linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(17, 8, 41, 0.88) 36.46%, #110829 71.88%, #110829 100%);
  --background-gradient2:linear-gradient(196deg, #54244E 9.51%, rgba(84, 36, 78, 0) 48.46%);
  --color-logo-sidebar: white;
  --video-stream-modal: (12, 16, 51, 0.40);

  // card colors
  --card-color:#091147;

  // Date Pickers
  --date-picker-range:#0A0D2A;

  //sidebar colors
  --sidebar-link: #fff;
  --sidebar-btn: #1A1D24;

  //Borders
  --theme-border:#434343;

  //Box Shadows
  --theme-shadow: rgba(0, 0, 0, 0.05);

  //Font
  --font-color-dark-1:#333;
  --font-color-grey-1:#929399;

 //input
  --input-background:#fff;
  --input-icon:#979797;
  --input-placeholder:#BEBEBE;
  --input-border:#DAD7D7;

  //  Chat
  --chat-message-color:#252851;
  $ChatMessReq: #252851;

  // Checkbox
  --checkbox-border:#38DFE4;

}

[data-theme='light']{
  --primary-color:var(--g1, linear-gradient(270deg, #38DFE4 0%, #01B0C3 100%));
  --secondary-color:#E7B300;
  --background: #fff;
  --background-2: #f5f5f5;
  --stroke-color:var(--stroke-g2, linear-gradient(136deg, rgba(226, 100, 41, 0.50) 3.06%, rgba(247, 183, 72, 0.45) 30.88%, rgba(231, 179, 0, 0.06) 75.64%));
  --natural-dark:#000;
  --natural-dark-2:#0C1033;
  --natural-light:#707581;
  --natural-secondary-1:#A8A9BA;
  --natural-secondary-2:#F8F8F8;
  --natural-secondary-3:#FAFBFF;
  --background-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.88) 25.9%, #FFF 71.88%, #FFF 100%);
  --background-gradient2:#fdfdfd;
  --video-stream-modal: #f5f5f5;

  // card colors
  --card-color:white;

  // Date Pickers
  --date-picker-range:#e7e7e7;

  //sidebar colors
  --sidebar-link: #0C1033;
  --sidebar-btn: #D7D7D7;
  --color-logo-sidebar: #38DFE4;

  //Borders
  --theme-border:#eee;

  //Font
  --font-color-dark-1:#0C1033;
  --font-color-grey-1:#707581;

  //input
  --input-background:#F8F8F8;
  --input-icon:#A8A9BA;
  --input-placeholder:#A8A9BA;

  //  Chat
  --chat-message-color:#e7e7e7;
    $ChatMessReq: #e7e7e7;

  // Checkbox
  --checkbox-border:#707581;
}

