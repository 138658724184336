.description-field{
  &.required{
    .field-label{
      .steric{
        display:inline-block;
      }
    }
  }
  .field-label{
    color: #333;
    font-family: var(--font-family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    .steric{
      color: var(--secondary-color);
      font-family: var(--font-family);
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: none;
    }
  }
  .ant-input{
    background: var(--input-background);
    //border: 1px solid var(--theme-border);
    color: var(--font-color-dark-1);
    font-size: 15px;
    font-family: var(--font-family);
    font-weight: 400;
    padding: 15px;
    &::placeholder{
      color: var(--input-placeholder);
    }
    .ant-input-prefix{
      color: var(--font-color-dark-1);
      svg{
        path{
          fill:var(--input-icon);
        }
      }
    }
  }
}