.bio-page{
  p{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000000;
  }
  .hide{
    display: none;
  }
  .bio-list{
    padding: 40px 0 0 0;
    ul{
      margin-top: 30px;
      li{
        font-family: Poppins;
        font-size: 26px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: #CBCBCB;
        margin-bottom: 20px;
        cursor: pointer;
        &:last-child{
          border-bottom: 1px solid #D2D2D2;
          padding-bottom: 40px;
        }
        &.active{
          color: #00BFB2;
        }
      }
    }
  }
  .section-1{
    padding: 40px 0;
    .p-s{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      margin-bottom: 70px;
    }
    .sec-1-box{
      background: #F3FBFA;
      height: 690px;
      padding: 30px;
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      &.green{
        background: #C4EAE8;
      }
      h3{
        font-family: Poppins;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 30px;
      }
      p{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
      }
      img{
        max-width: 175px;
        margin-bottom: 30px;
      }
    }
  }
  .section-2{
    padding: 0 0 40px 0;
  }
  .section-3{
    padding: 0 0 40px 0;
  }
  .section-4{
    padding: 0 0 40px 0;
  }
}