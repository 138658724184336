.footer{
    background:#000;
   .row-1{
       padding: 60px 0 30px 0;
   }
    .f-row{
        text-align:left;
        column-count: 2;
        column-gap: 20px; // Adjust gap between columns
        padding: 0;
        list-style-type: none;
        max-width: 300px;
        li{
            list-style-type:none;
            padding-bottom:20px;
            a{
                color:#bababa;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                text-align: left;

            }
        }
    }
    .utrust-logo{
        width:180px;
        // height:180px;
        object-fit:contain;
        margin-bottom:20px;
        
    }
    .newsletter-heading{
        color:#fff;
        font-family: Poppins;
        font-size: 28px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
        margin-bottom:30px;
    }

    form{
        .email-input{
            background:transparent;
            border:1px solid #333;
            text-align:left;
            display:flex;
            height:48px;
            input{
                background:transparent;
                color:#fff;
                padding-left:20px;
                border:none;
                flex:0.9;

                &:focus{
                    outline:none;
                    border:none;
                }
            }

            .arrow-right-icon{
                background: #1fd8d8;
                color: #fff;
                height: 80%;
                flex: 0.1 1;
                cursor: pointer;
                font-size: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 5px;
            }
        }
    }

    .footer-info{
        margin-top:20px;
        border-top:1px solid #333;
        border-bottom:1px solid #333;
        padding:20px 0;
        display:flex;
        justify-content:space-between;
        flex-wrap:wrap;
        position:relative;

        // &::before{
        //     content: '';
        //     position:absolute;
        //     top:0;
        //     left:-120px;
        //     width:99vw;
        //     height:1px;
        //     border-top:1px solid #333;
        //     // margin: 0 auto;
        // }
        p{
            color:#fff;
            margin-bottom:0;
        }

        div{
            display:flex;
            flex-wrap:wrap;
            gap:20px;
        }
    }
    .footer-copyright{
        position:relative;
        // border-top:1px solid #333;
        // border-bottom:1px solid #333;
        color:#fff;
        display:flex;
        justify-content:space-between;
        align-items:center;
        flex-wrap:wrap;
        padding:10px 0;

        // &::before{
        //     content: '';
        //     position:absolute;
        //     top:0;
        //     left:-120px;
        //     width:99vw;
        //     height:1px;
        //     border-bottom:1px solid #333;
        //     margin: 0 auto;
        // }
        // &::after{
        //     content: '';
        //     position:absolute;
        //     bottom:0;
        //     left:-120px;
        //     width:99vw;
        //     height:1px;
        //     border-top:1px solid #333;
        //     margin: 0 auto;
        // }
        p{
            margin-bottom:0;
        }
        .social-icon{
            font-size:18px;
        }
        ul{
            text-align:left;
            li{
                list-style-type:none;
                padding-bottom:20px;
    
                a{
                    color:#fff;
                }
            }
        }

        .terms{
            ul{
                display:flex;
                gap:20px;

                li{
                    padding:0;
                }
            }
        }
    }
}

.teal{
    color:rgb(0, 175, 175);
    font-weight:600;
}