.login{
  h3{
    color:var(--font-color-dark-1);
    font-family:var(--font-family);
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 44px; /* 146.667% */
    letter-spacing: 0.114px;
    display: flex;
    align-items: center;
    .btn-text{
      font-size: 24px;
      color:var(--font-color-dark-1);
      padding-left: 0;
    }
  }
  h5{
    color:var(--font-color-grey-1);
    font-family:var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 35px;
  }
  .forget-pass{
    color: var(--secondary-color);
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 35px;
    cursor: pointer;
  }
  .or{
    position: relative;
    text-align: center;
    margin: 24px 0;
    color:var(--font-color-dark-1);
    &:before{
      position: absolute;
      content: '';
      background:#7075814a;
      width: 45%;
      height: 1px;
      left: 0;
      top: 50%;
    }
    &:after{
      position: absolute;
      content: '';
      background: #7075814a;
      width: 45%;
      height: 1px;
      right: 0;
      top: 50%;
    }
  }
  .other-option{
    color:var(--font-color-dark-1);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    span{
      color: var(--secondary-color);
    }
  }
}