.theme-modal{
  &.modal-dialog{
    .modal-content{
      background:var(--card-color);
    }
    @media screen and (min-width: 576px){
      max-width: 500px;
    }
  }
  .theme-modal-body{
    //padding: 30px 0 20px 0;
  }
  &.with-shadow{
    .theme-modal-header{
      &:before{
        position: absolute;
        content: '';
        background: #B900AA;
        filter: blur(160px);
        width: 215.259px;
        height: 218.289px;
        transform: rotate(-31.357deg);
        right: -85.322px;
        top: -72.137px;
      }
    }
  }
  .theme-modal-header{
    position: relative;
    .modal-close{
      position: relative;
    }

    h3{
      color: var(--font-color-dark-1);
      font-family: Inter;
      font-style: normal;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--theme-border);
      padding: 15px 0;
      span{
        cursor: pointer;
      }
    }
    p{
      color: var(--grey, #A8A9BA);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
    }
  }
  .theme-modal-btns{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
  }
}