.surveilance{
  p{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000000;
  }
  .sub-heading-3{
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    line-height: 46px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 40px;
  }
  .hide{
    display: none;
  }

  .section-1{
    padding: 40px 0;
    .p-s{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      margin-bottom: 70px;
    }
    .sec-1-box{
      background: #F3FBFA;
      height: 690px;
      padding: 30px;
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      &.green{
        background: #C4EAE8;
      }
      h3{
        font-family: Poppins;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 30px;
      }
      p{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
      }
      img{
        max-width: 175px;
        margin-bottom: 30px;
      }
      ul{
        li{
          margin-bottom: 15px;
          font-family: Poppins;
          font-size: 17px;
          font-weight: 400;
          line-height: 30px;
          span{
            font-weight: 700;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .home-featured-products{
    background: #F3FBFA;
  }
  .section-2{
    padding: 70px 0;
  }
}