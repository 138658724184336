.ant-notification {
  font-family: "Inter", sans-serif;
  .ant-notification-notice-wrapper{
    border-radius: 12px;
  }
  .ant-notification-notice {
    padding: 15px;
    background: var(--background-2);
    border-radius: 8px;
    //border: 1px solid var(--theme-border);
    .ant-notification-notice-close{
      color: #97979759;
      font-size: 20px;
      &:hover{
        color: var(--font-color-dark-1);
      }
    }
    .ant-notification-notice-with-icon {
      display: flex;
      align-items: flex-start;
      flex-flow: column;
      justify-content: center;
      .ant-notification-notice-message {
        margin-inline-start: 50px;
        color: var(--font-color-dark-1);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: none;
      }
      .ant-notification-notice-description {
        margin-inline-start: 50px;
        color: white;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }
    }
  }
}
