.contact-info{
  padding: 100px 10px;
  background: white;
  h2{
    text-align: center;
  }
  .info-section{
    margin-top: 60px;
    ul{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
        position: relative;
        width: 25%;
        &:after{
          width: 1px;
          height: 40%;
          position: absolute;
          content: '';
          right: 0;
          top: 20%;
          background: rgba(0,0,0,0.2);
        }
        &:last-child{
          &:after{
            background: transparent;
          }
        }
        .info-box{
          max-width: 240px;
          text-align: center;
          margin: auto;
          height: 200px;
          .icon{
            display: block;
            width: 80px;
            height: 80px;
            border: 1px solid #00BFB2;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #00BFB2;
            font-size: 22px;
            margin: auto;
            margin-bottom: 20px;
          }
          h5{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 18px;
            letter-spacing: -0.02em;
            color: #000000;
            margin-bottom: 10px;
          }
          p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 22px;
            text-align: center;
            //text-transform: capitalize;
            color: #2C2C2C;
          }
        }
      }
    }
  }
}

.contact-form{
  background: white;
  padding: 100px 10px;
  .heading{
    font-size: 40px;
  }
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #2C2C2C;
  }
}


@media screen and (min-width: 1600px) and (max-width: 1800px){
  .contact-info{
    padding: 100px 10px;
    background: white;
    h2{
      text-align: center;
    }
    .info-section{
      margin-top: 60px;
      ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        li{
          position: relative;
          width: 25%;
          &:after{
            width: 1px;
            height: 40%;
            position: absolute;
            content: '';
            right: 0;
            top: 20%;
            background: rgba(0,0,0,0.2);
          }
          &:last-child{
            &:after{
              background: transparent;
            }
          }
          .info-box{
            max-width: 240px;
            text-align: center;
            margin: auto;
            height: 200px;
            .icon{
              display: block;
              width: 80px;
              height: 80px;
              border: 1px solid #00BFB2;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #00BFB2;
              font-size: 22px;
              margin: auto;
              margin-bottom: 20px;
            }
            h5{
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 18px;
              letter-spacing: -0.02em;
              color: #000000;
              margin-bottom: 10px;
            }
            p{
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 22px;
              text-align: center;
              text-transform: capitalize;
              color: #2C2C2C;
            }
          }
        }
      }
    }
  }

  .contact-form{
    padding: 100px 10px;
    .heading{
      font-size: 40px;
    }
    p{
      font-size: 20px;
      line-height: 32px;
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px){
  .contact-info{
    padding: 90px 10px;
    h2{

    }
    .info-section{
      margin-top: 50px;
      ul{
        li{
          width: 25%;
          &:after{
            width: 1px;
            height: 40%;
            right: 0;
            top: 20%;
          }
          &:last-child{
            &:after{

            }
          }
          .info-box{
            max-width: 240px;
            margin: auto;
            height: 200px;
            .icon{
              width: 80px;
              height: 80px;
              border: 1px solid #00BFB2;
              color: #00BFB2;
              font-size: 22px;
              margin-bottom: 20px;
            }
            h5{
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;
              color: #000000;
              margin-bottom: 10px;
            }
            p{
              font-weight: 400;
              font-size: 17px;
              line-height: 22px;
              color: #2C2C2C;
            }
          }
        }
      }
    }
  }

  .contact-form{
    padding: 90px 10px;
    .heading{
      font-size: 40px;
    }
    p{
      font-size: 20px;
      line-height: 32px;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
  .contact-info{
    padding: 90px 10px;
    h2{

    }
    .info-section{
      margin-top: 50px;
      ul{
        li{
          width: 25%;
          &:after{
            width: 1px;
            height: 40%;
            right: 0;
            top: 20%;
          }
          &:last-child{
            &:after{

            }
          }
          .info-box{
            max-width: 220px;
            margin: auto;
            height: 180px;
            .icon{
              width: 70px;
              height: 70px;
              border: 1px solid #00BFB2;
              font-size: 20px;
              margin-bottom: 15px;
            }
            h5{
              font-weight: 600;
              font-size: 17px;
              line-height: 18px;
              color: #000000;
              margin-bottom: 10px;
            }
            p{
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #2C2C2C;
            }
          }
        }
      }
    }
  }

  .contact-form{
    padding: 40px 10px 90px 10px;
    .heading{
      font-size: 40px;
    }
    p{
      font-size: 20px;
      line-height: 32px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
  .contact-info{
    padding: 80px 10px;
    h2{

    }
    .info-section{
      margin-top: 50px;
      ul{
        li{
          width: 50%;
          &:after{
            width: 1px;
            height: 40%;
            right: 0;
            top: 20%;
          }
          &:nth-of-type(2){
            &:after{
              background: transparent;
            }
          }
          &:last-child{
            &:after{

            }
          }
          .info-box{
            max-width: 220px;
            margin: auto;
            height: 180px;
            .icon{
              width: 70px;
              height: 70px;
              border: 1px solid #00BFB2;
              font-size: 20px;
              margin-bottom: 15px;
            }
            h5{
              font-weight: 600;
              font-size: 17px;
              line-height: 18px;
              color: #000000;
              margin-bottom: 10px;
            }
            p{
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #2C2C2C;
            }
          }
        }
      }
    }
  }
  .contact-form{
    padding: 40px 10px 90px 10px;
    .heading{
      font-size: 40px;
    }
    p{
      font-size: 20px;
      line-height: 32px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px){
  .contact-info{
    padding: 70px 10px;
    h2{

    }
    .info-section{
      margin-top: 40px;
      ul{
        li{
          width: 50%;
          &:after{
            width: 1px;
            height: 40%;
            right: 0;
            top: 20%;
          }
          &:nth-of-type(2){
            &:after{
              background: transparent;
            }
          }
          &:last-child{
            &:after{

            }
          }
          .info-box{
            max-width: 220px;
            margin: auto;
            height: 180px;
            .icon{
              width: 70px;
              height: 70px;
              border: 1px solid #00BFB2;
              font-size: 20px;
              margin-bottom: 15px;
            }
            h5{
              font-weight: 600;
              font-size: 17px;
              line-height: 18px;
              color: #000000;
              margin-bottom: 10px;
            }
            p{
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #2C2C2C;
            }
          }
        }
      }
    }
  }
  .contact-form{
    padding: 40px 10px 90px 10px;
    .heading{
      font-size: 40px;
    }
    p{
      font-size: 20px;
      line-height: 32px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px){
  .contact-info{
    padding: 70px 10px;
    h2{

    }
    .info-section{
      margin-top: 40px;
      ul{
        li{
          width: 50%;
          &:after{
            width: 1px;
            height: 40%;
            right: 0;
            top: 20%;
          }
          &:nth-of-type(2){
            &:after{
              background: transparent;
            }
          }
          &:last-child{
            &:after{

            }
          }
          .info-box{
            max-width: 220px;
            margin: auto;
            height: 180px;
            .icon{
              width: 70px;
              height: 70px;
              border: 1px solid #00BFB2;
              font-size: 20px;
              margin-bottom: 15px;
            }
            h5{
              font-weight: 600;
              font-size: 17px;
              line-height: 18px;
              color: #000000;
              margin-bottom: 10px;
            }
            p{
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #2C2C2C;
            }
          }
        }
      }
    }
  }
  .contact-form{
    padding: 30px 10px 90px 10px;
    .heading{
      font-size: 40px;
    }
    p{
      font-size: 20px;
      line-height: 32px;
    }
  }
}

@media screen and (max-width: 575px){
  .contact-info{
    padding: 70px 10px;
    h2{

    }
    .info-section{
      margin-top: 40px;
      ul{
        li{
          width: 100%;
          &:after{
           background: transparent;
          }
          &:nth-of-type(2){
            &:after{
              background: transparent;
            }
          }
          &:last-child{
            &:after{

            }
          }
          .info-box{
            max-width: 220px;
            height: 200px;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            margin: 30px auto;
            .icon{
              width: 70px;
              height: 70px;
              border: 1px solid #00BFB2;
              font-size: 20px;
              margin-bottom: 15px;
            }
            h5{
              font-weight: 600;
              font-size: 17px;
              line-height: 18px;
              color: #000000;
              margin-bottom: 10px;
            }
            p{
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              color: #2C2C2C;
            }
          }
        }
      }
    }
  }

  .contact-form{
    padding: 30px 10px 90px 10px;
    .heading{
      font-size: 40px;
    }
    p{
      font-size: 20px;
      line-height: 32px;
    }
  }
}