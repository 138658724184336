.ant-tabs{
  .ant-tabs-nav{
    &:before{
      border: 1px solid var(--theme-border);
    }
  }
  .ant-tabs-nav-list{
    .ant-tabs-tab-btn{
      color: #97979759;
      text-align: center;
      font-family: var(--font-family);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
    .ant-tabs-tab-active{
      .ant-tabs-tab-btn{
        color: var(--font-color-dark-1);
      }
    }
  }
  .ant-tabs-ink-bar{
    background: #D2A84E;
    width: 40px;
    height: 3px;
  }
}