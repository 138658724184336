.text-field{
  &.required{
    .field-label{
      .steric{
        display:inline-block;
      }
    }
  }
  .field-label{
    color: #333;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    .steric{
      color: var(--secondary-color);
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: none;
    }
  }
  .ant-input{
    background: var(--input-background);
  }
  .ant-input-affix-wrapper{
    //border-radius: 100px;
    //border: 1px solid var( --input-border);
    background: var(--input-background);
    padding: 10px 15px;
    .ant-input-prefix{
      color:var(--input-icon);
      svg{
        path{
          fill:var(--input-icon);
        }
      }
    }
    .ant-input-suffix{
      cursor: pointer;
      font-size: 23px;
      color: var(--input-icon);
      svg{
        path{
          fill:var(--input-icon);
        }
      }
      .anticon{
        color: #717392 !important;
      }
    }
    .ant-input{
      //background: transparent;
      color: var(--font-color-dark-1);
      font-family: Inter;
      font-size: 14px;
      height: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:focus{
        //background: transparent;
      }
      &::placeholder{
        color: var(--input-placeholder);
      }
    }
  }

  input{
    //border-radius: 100px;
    border: 1px solid var( --input-border);
    background: var(--input-background);
    height: 50px;
    padding: 10px 15px;
    color: var(--font-color-dark-1);
    &::placeholder{
      color: var(--input-placeholder);
    }
    .ant-input-prefix{
      color: var(--font-color-dark-1);
      svg{
        path{
          fill:var(--input-icon);
        }
      }
    }
    .ant-input-suffix{
      cursor: pointer;
      font-size: 23px;
      color: var(--input-icon);
      .anticon{
        color: var(--input-icon) !important;
        svg{
          path{
            fill:var(--input-icon);
          }
        }
      }
    }
    &.ant-input{
      color: var(--font-color-dark-1);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &::placeholder{
        color: #BEBEBE;
      }
    }
  }
  .error-message{
    color: #ff4d4f;
  }
}

//:where(.css-dev-only-do-not-override-4ltwpz).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
//  background: var(--input-background);
//}
//:where(.css-dev-only-do-not-override-4ltwpz).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):hover {
//  background: var(--input-background);
//}
//:where(.css-dev-only-do-not-override-sfqd18).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled){
//  background: var(--input-background);
//}
//.ant-input-affix-wrapper-focused{
//  .ant-input{
//    background: var(--input-background);
//  }
//
//}