.home-banner{
  background: url("../../../../assets/website-images/home/banner.png");
  height: 980px;
  background-size: cover;
  background-position: center;
  position: relative;
  .scroll-icon{
    position: absolute;
    left: 50%;
    bottom: 7%;
    transform: translate(-50%, -0);
  }
  h4{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 70px;
    color: #00BFB2;
  }
  h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 65px;
    line-height: 70px;
    letter-spacing: -1px;
    //text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
  .social-sec{
    display: flex;
    align-items: center;
    gap: 25px;
    position: absolute;
    bottom: 9%;
    left: 9%;
    p{
      font-family: Poppins;
      font-size: 23px;
      font-weight: 500;
      text-align: left;
      color: white;
      margin: 0;
    }
    span{
      font-size: 18px;
      color: white;
      cursor: pointer;
    }
  }
  .chat-icons{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    position: absolute;
    right: 5%;
    bottom: 9%;
    span{
      background: #00BFB2;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.home-about{
  padding:80px 0;
  text-align:left;

  .heading-section{
    max-width: 900px;

    .para{
      font-family: Poppins;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
    }
  }

  .about-img{
    width:100%;
    height:100%;
    // height:480px;
    object-fit:cover;
  }

  .cards{
    padding-top:40px;
    .about-card{
      padding:20px;
      border:1px solid #E3E3E3;
      min-height:240px;
      border-radius: 2px;
      img{
        margin-bottom:20px;
      }
      h4{
        font-family: 'Poppins';
        font-size: 36px;
        font-weight: 500;
        line-height: 43px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 20px;
      }
      p{
        font-family: 'Poppins';
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
    }
  }
}


.home-featured-products{
  padding:80px 0;
  text-align:left;
  .headers{
    display:flex;
    justify-content:space-between;
    align-items:center;
    .heading{
      max-width: 700px;
    }

    .view-all{
      display:flex;
      align-items:center;
      gap:10px;
      background:rgb(0, 175, 175);
      color:#fff;
      border-radius:30px;
      padding:5px 20px;
      button{
        border:none;
        background:transparent;
        color:#fff;
      }
    }
  }

  .cards{
    padding-top:40px;
    .product-card{
      display:flex;
      flex-wrap:wrap;
      gap:20px;
      align-items:center;
      margin-bottom:40px;
      .card-img{
        // max-width:300px;
        width:100%;
        height:100%;
        object-fit:cover;
        flex:0.5;
      }

      .card-info{
        // flex:0.5;
        display:flex;
        flex-direction:column;
        justify-content: center;
        height:100%;
        h4{
          font-family: Poppins;
          font-size: 26px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #000000;
          margin-bottom: 15px;
        }
        p{
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #000000;
        }
      }
    }
  }
}

.home-resources{
  padding:80px 0;
  text-align:left;
  .headers{
    display:flex;
    justify-content:space-between;
    align-items:center;
    .heading{
      max-width: 500px;
    }

    .view-all{
      display:flex;
      align-items:center;
      gap:10px;
      background:rgb(0, 175, 175);
      color:#fff;
      border-radius:30px;
      padding:5px 20px;
      button{
        border:none;
        background:transparent;
        color:#fff;
      }
    }
  }

  .swiper-container{
    padding-left:10px;
    margin-top: 20px;
    width:90%;
    margin-left:auto;
    position:relative;
    .resource-card{
      .card-img{
        width:100%;
        border-top-left-radius:10px;
        margin-bottom: 15px;
      }

      .card-info{
        .date{
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #00BFB2;
          margin-bottom: 15px;
        }
        h4{
          font-family: Poppins;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: -0.02em;
          text-align: left;
          color: #000000;
          margin-bottom: 15px;
        }

        .read-more{
          button{
            border:none;
            background:rgb(0, 175, 175);
            color:#fff;
            padding:5px 20px;
            border-radius:30px;
          }
        }
      }
    }




    .swiper-button-prev,
    .swiper-button-next {
      background-color: white;
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      cursor: pointer;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

      &:after{
        display:none;
      }

      svg{
        width:40%;
        height:40%;
        color:#000;
      }
    }

    .swiper-button-prev{
      position:absolute;
      left:-8%;
      top:40%;
      background:#fff;
      border:1px solid #000;
    }
    .swiper-button-next {
      // margin-top: 30px;
      position:absolute;
      top:60%;
      left:-8%;
      background:#fff;
      border:1px solid #000;
    }

  }
}
@media (max-width: 992px) {
  .home-resources .swiper-container{
    width:100% !important;
  }
  .home-resources .swiper-button-prev {
    left: 0 !important;
    top: 40% !important;
  }

  .home-resources .swiper-button-next {
    margin-top:0;
    right: -2% !important;
    top: 40% !important;
    left: auto !important;
  }
}



.home-featured-services{
  // height:100vh;
  background:url('../../../../assets/website-images/fs-image-1.jpg') no-repeat;
  background-size:cover;
  position:relative;
  height: 800px;
  .heading{
    color: #0CFFEE;
  }
  .subheading{
    color: white;
  }
  p{
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: white;
    margin-bottom: 30px;
  }

  .iam-solutions-card{
    background:#fff;
    width:250px;
    // height:100px;
    padding:30px 20px;
    position:absolute;
    right:0;
    bottom:20%;
    border-top-left-radius:30px;
    border-bottom-left-radius:30px;

    h4{
      font-family: Poppins;
      font-size: 30px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: -1px;
      text-align: left;

    }

    .arrow-right{
      position:relative;
      .icon-container{
        .arrow-right-icon{
          background:rgb(0, 175, 175);
          color:#fff;
          position:absolute;
          left:-35px;
          bottom:5px;
          width:28px;
          height:28px;
          padding:8px;
        }
      }
    }
  }
}

@media(max-width:1400px){
  .featured-services{
    .info{
      padding:220px 0 90px 0;
    }
  }
}

@media(max-width:1200px){
  .featured-services{
    .info{
      padding:190px 0 80px 0;
    }
  }
}
@media(max-width:992px){
  .featured-services{
    .info{
      padding:150px 0 70px 0;
    }
    .iam-solutions-card{
      width:170px !important;
      bottom:55% !important;
    }
  }
}
@media(max-width:768px){
  .featured-services{
    .info{
      padding:110px 0 60px 0;
    }
    .iam-solutions-card{
      width:150px !important;
      bottom:55% !important;
    }
  }

}

@media(max-width:556px){
  .featured-services{
    .info{
      padding:60px 0 50px 0;
    }
    .iam-solutions-card{
      width:120px !important;
      bottom:55% !important;
    }
  }

}


.home-services{
  background: rgb(204, 242, 240);
  padding-top: 70px;
  overflow: hidden;
  .home-service-slider{
    max-width: 600px;
    margin: auto;
    padding: 80px 0;
    .line-content-slider{
      text-align: center;
      .icon{
        margin-bottom: 20px;
      }
      h4{
        font-family: Poppins;
        font-size: 34px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -1px;
        text-align: center;
      }
      p{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
      }
      span{
        font-family: Poppins;
        font-size: 15px;
        font-weight: 600;
        line-height: 21.86px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #00BFB2;
      }
    }
  }

  .heading-section{
    max-width: 700px;
    margin: auto;
    text-align: center;
  }
  .line-sec{
    width: 1466px;
    height: 608px;
    margin: auto;
    position: relative;
    margin-bottom: -60px;
    margin-top: 140px;
    .line-content{
      position: absolute;
      top: 30%;
      left: 30%;
      transform: translate(0, 0);
      max-width: 600px;
      text-align: center;
      .icon{
        margin-bottom: 20px;
      }
      h4{
        font-family: Poppins;
        font-size: 34px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -1px;
        text-align: center;
      }
      p{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
      }
      span{
        font-family: Poppins;
        font-size: 15px;
        font-weight: 600;
        line-height: 21.86px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #00BFB2;
      }
    }
    .line-box{
      p{
        display: flex;
        align-items: start;
        justify-content: start;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        width: 220px;
        gap: 10px;
        span{
          color: #00BFB2;
          font-size: 22px;
        }
      }
    }
    .line-box-1{
      position: absolute;
      top: 18.7%;
      left: 7.5%;
    }
    .line-box-2{
      position: absolute;
      top: 52%;
      left: -6.4%;
    }
    .line-box-3{
      position: absolute;
      top: 18.4%;
      right: 7.5%;
      p{
        flex-direction: row-reverse;
      }
    }
    .line-box-4{
      position: absolute;
      top: 51%;
      right: -6.4%;
      p{
        flex-direction: row-reverse;
      }
    }
    .line-box-5{
      position: absolute;
      top: -15%;
      right: 42.6%;
      text-align: center;
      p{
        text-align: center;
        flex-direction: row-reverse;
      }
    }
  }
}



@media screen and (min-width: 1600px) and (max-width: 1800px){
  .home-banner{
    height: 900px;
    .scroll-icon{
      left: 50%;
      bottom: 7%;
      transform: translate(-50%, -0);
    }
    h4{
      font-size: 26px;
      line-height: 70px;
    }
    h1{
      font-size: 52px;
      line-height: 60px;
      margin-bottom: 30px;
    }
    .social-sec{
      gap: 25px;
      bottom: 9%;
      left: 9%;
      p{
        font-size: 23px;
        margin: 0;
      }
      span{
        font-size: 18px;
      }
    }
    .chat-icons{
      gap: 20px;
      position: absolute;
      right: 5%;
      bottom: 9%;
      span{
        width: 62px;
        height: 62px;
        border-radius: 50%;
      }
    }
  }
  .home-about{
    padding:80px 0;

    .heading-section{
      max-width: 900px;
      .para{
        font-size: 17px;
        line-height: 30px;
      }
    }

    .about-img{
      width:100%;
      height:100%;
      // height:480px;
      object-fit:cover;
    }

    .cards{
      padding-top:40px;
      .about-card{
        padding:20px;
        min-height:240px;
        img{
          margin-bottom:20px;
        }
        h4{
          font-size: 36px;
          line-height: 43px;
          margin-bottom: 20px;
        }
        p{
          font-size: 17px;
          line-height: 24px;
        }
      }
    }
  }

  .home-services{
    padding-top: 70px;
    overflow: hidden;
    .heading-section{
      max-width: 700px;
      .heading{
      }
      .subheading{
        margin-bottom: 10px;
      }
    }
    .line-sec{
      width: 1466px;
      height: 608px;
      margin: auto;
      position: relative;
      margin-bottom: -60px;
      margin-top: 140px;
      .line-content{
        position: absolute;
        top: 30%;
        left: 30%;
        transform: translate(0, 0);
        max-width: 600px;
        text-align: center;
        .icon{
          margin-bottom: 20px;
        }
        h4{
          font-size: 34px;
          line-height: 60px;
        }
        p{
          font-size: 18px;
          line-height: 26px;
        }
        span{
          font-size: 15px;
          line-height: 21.86px;
        }
      }
      .line-box{
        p{
          font-size: 18px;
          line-height: 26px;
          width: 220px;
          gap: 10px;
          span{
            font-size: 22px;
          }
        }
      }
      .line-box-1{
        top: 18.7%;
        left: 7.5%;
      }
      .line-box-2{
        top: 52%;
        left: -6.4%;
      }
      .line-box-3{
        top: 18.4%;
        right: 7.5%;
        p{

        }
      }
      .line-box-4{
        top: 51%;
        right: -6.4%;
        p{

        }
      }
      .line-box-5{
        top: -15%;
        right: 42.6%;
        p{

        }
      }
    }
  }

  .home-featured-products{
    padding:80px 0;
    .headers{
      display:flex;
      justify-content:space-between;
      align-items:center;
      .heading{
        max-width: 700px;
      }
      .view-all{
        gap:10px;
        border-radius:30px;
        padding:5px 20px;
      }
    }

    .cards{
      padding-top:40px;
      .product-card{
        gap:20px;
        margin-bottom:40px;
        .card-img{
          width:100%;
          height:100%;
          object-fit:cover;
          flex:0.5;
        }
        .card-info{
          height:100%;
          h4{
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 15px;
          }
          p{
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .home-featured-services{
    height: 800px;
    .heading{
      color: #0CFFEE;
    }
    .subheading{
      color: white;
    }
    p{
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 30px;
    }

    .iam-solutions-card{
      width:250px;
      padding:30px 20px;
      right:0;
      bottom:20%;
      border-top-left-radius:30px;
      border-bottom-left-radius:30px;

      h4{
        font-size: 30px;
        line-height: 40px;
      }

      .arrow-right{
        position:relative;
        .icon-container{
          .arrow-right-icon{
            background:rgb(0, 175, 175);
            color:#fff;
            left:-35px;
            bottom:5px;
            width:28px;
            height:28px;
            padding:8px;
          }
        }
      }
    }
  }

  .home-resources {
    padding: 80px 0;
  }

}

@media screen and (min-width: 1400px) and (max-width: 1599px){
  .home-banner{
    height: 850px;
    .scroll-icon{
      left: 50%;
      bottom: 7%;
      transform: translate(-50%, -0);
    }
    h4{
      font-size: 26px;
      line-height: 70px;
    }
    h1{
      font-size: 52px;
      line-height: 60px;
      margin-bottom: 30px;
    }
    .social-sec{
      gap: 25px;
      bottom: 8%;
      left: 5%;
      p{
        font-size: 20px;
        margin: 0;
      }
      span{
        font-size: 18px;
      }
    }
    .chat-icons{
      gap: 20px;
      position: absolute;
      right: 5%;
      bottom: 9%;
      span{
        width: 58px;
        height: 58px;
        border-radius: 50%;
      }
    }
  }

  .home-about{
    padding:80px 0;

    .heading-section{
      max-width: 900px;
      .para{
        font-size: 17px;
        line-height: 30px;
      }
    }

    .about-img{
      width:100%;
      height:100%;
      // height:480px;
      object-fit:cover;
    }

    .cards{
      padding-top:40px;
      .about-card{
        padding:20px;
        min-height:240px;
        img{
          margin-bottom:20px;
        }
        h4{
          font-size: 36px;
          line-height: 43px;
          margin-bottom: 20px;
        }
        p{
          font-size: 17px;
          line-height: 24px;
        }
      }
    }
  }

  .home-services{
    padding-top: 70px;
    overflow: hidden;
    .heading-section{
      max-width: 700px;
      .heading{
      }
      .subheading{
        margin-bottom: 10px;
      }
    }
    .line-sec{
      width: 1266px;
      height: 608px;
      margin: auto;
      position: relative;
      margin-bottom: -60px;
      margin-top: 140px;
      img{
        width: 1266px;
      }
      .line-content{
        position: absolute;
        top: 26%;
        left: 28%;
        transform: translate(0, 0);
        max-width: 600px;
        text-align: center;
        .icon{
          margin-bottom: 20px;
        }
        h4{
          font-size: 34px;
          line-height: 60px;
        }
        p{
          font-size: 18px;
          line-height: 26px;
        }
        span{
          font-size: 15px;
          line-height: 21.86px;
        }
      }
      .line-box{
        p{
          font-size: 18px;
          line-height: 26px;
          width: 220px;
          gap: 10px;
          span{
            font-size: 22px;
          }
        }
      }
      .line-box-1{
        top: 15.7%;
        left: 5.5%;
        p{

        }
      }
      .line-box-2{
        top: 33%;
        left: -4.4%;
        p{

        }
      }
      .line-box-3{
        top: 15.4%;
        right: 5.5%;
        p{

        }
      }
      .line-box-4{
        top: 34%;
        right: -4.8%;
        p{

        }
      }
      .line-box-5{
        top: -15%;
        right: 42.6%;
        p{

        }
      }
    }
  }

  .home-featured-products{
    padding:80px 0;
    .headers{
      display:flex;
      justify-content:space-between;
      align-items:center;
      .heading{
        max-width: 700px;
      }
      .view-all{
        gap:10px;
        border-radius:30px;
        padding:5px 20px;
      }
    }

    .cards{
      padding-top:40px;
      .product-card{
        gap:20px;
        margin-bottom:40px;
        .card-img{
          width:100%;
          height:100%;
          object-fit:cover;
          flex:0.5;
        }
        .card-info{
          height:100%;
          h4{
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 15px;
          }
          p{
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .home-featured-services{
    height: 700px;
    .heading{
      color: #0CFFEE;
    }
    .subheading{
      color: white;
    }
    p{
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 30px;
    }

    .iam-solutions-card{
      width:220px;
      padding:30px 20px;
      right:0;
      bottom:20%;
      border-top-left-radius:30px;
      border-bottom-left-radius:30px;

      h4{
        font-size: 28px;
        line-height: 40px;
      }

      .arrow-right{
        position:relative;
        .icon-container{
          .arrow-right-icon{
            background:rgb(0, 175, 175);
            color:#fff;
            left:-35px;
            bottom:5px;
            width:28px;
            height:28px;
            padding:8px;
          }
        }
      }
    }
  }

  .home-resources {
    padding: 70px 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
  .home-banner{
    height: 800px;
    .scroll-icon{
      left: 50%;
      bottom: 7%;
      transform: translate(-50%, -0);
    }
    h4{
      font-size: 24px;
      line-height: 50px;
    }
    h1{
      font-size: 46px;
      line-height: 60px;
      margin-bottom: 30px;
    }
    .social-sec{
      gap: 25px;
      bottom: 7%;
      left: 5%;
      p{
        font-size: 19px;
        margin: 0;
      }
      span{
        font-size: 17px;
      }
    }
    .chat-icons{
      gap: 20px;
      position: absolute;
      right: 5%;
      bottom: 7%;
      span{
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
    }
  }

  .home-about{
    padding:80px 0;

    .heading-section{
      max-width: 900px;
      .para{
        font-size: 17px;
        line-height: 30px;
      }
    }

    .about-img{
      width:100%;
      height:100%;
      // height:480px;
      object-fit:cover;
    }

    .cards{
      padding-top:30px;
      .about-card{
        padding:20px;
        min-height:240px;
        img{
          margin-bottom:20px;
        }
        h4{
          font-size: 30px;
          line-height: 38px;
          margin-bottom: 20px;
        }
        p{
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .home-services{
    padding-top: 70px;
    overflow: hidden;
    .heading-section{
      max-width: 700px;
      .heading{
      }
      .subheading{
        margin-bottom: 10px;
      }
    }
    .line-sec{
      display: none;
    }
  }

  .home-featured-products{
    padding:70px 0;
    .headers{
      display:flex;
      justify-content:space-between;
      align-items:center;
      .heading{
        max-width: 700px;
      }
      .view-all{
        gap:10px;
        border-radius:30px;
        padding:5px 20px;
      }
    }

    .cards{
      padding-top:40px;
      .product-card{
        gap:20px;
        margin-bottom:40px;
        .card-img{
          width:160px;
          height:160px;
          object-fit:cover;
          flex:0.5;
        }
        .card-info{
          height:100%;
          h4{
            font-size: 21px;
            line-height: 32px;
            margin-bottom: 15px;
          }
          p{
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .home-featured-services{
    height: 700px;
    .heading{
      color: #0CFFEE;
    }
    .subheading{
      color: white;
    }
    p{
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 30px;
    }

    .iam-solutions-card{
      width:220px;
      padding:30px 20px;
      right:0;
      bottom:20%;
      border-top-left-radius:30px;
      border-bottom-left-radius:30px;

      h4{
        font-size: 28px;
        line-height: 40px;
      }

      .arrow-right{
        position:relative;
        .icon-container{
          .arrow-right-icon{
            background:rgb(0, 175, 175);
            color:#fff;
            left:-35px;
            bottom:5px;
            width:28px;
            height:28px;
            padding:8px;
          }
        }
      }
    }
  }

  .home-resources {
    padding: 70px 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
  .home-banner{
    height: 700px;
    .scroll-icon{
      left: 50%;
      bottom: 7%;
      transform: translate(-50%, -0);
    }
    h4{
      font-size: 24px;
      line-height: 50px;
    }
    h1{
      font-size: 46px;
      line-height: 60px;
      margin-bottom: 30px;
    }
    .social-sec{
      gap: 25px;
      bottom: 7%;
      left: 5%;
      p{
        font-size: 19px;
        margin: 0;
      }
      span{
        font-size: 17px;
      }
    }
    .chat-icons{
      gap: 20px;
      position: absolute;
      right: 5%;
      bottom: 7%;
      span{
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
    }
  }

  .home-about{
    padding:80px 0;

    .heading-section{
      max-width: 900px;
      .para{
        font-size: 17px;
        line-height: 30px;
      }
    }

    .about-img{
      width:100%;
      height:100%;
      // height:480px;
      object-fit:cover;
      margin-top: 30px;
    }

    .cards{
      padding-top:30px;
      .about-card{
        padding:20px;
        min-height:240px;
        img{
          margin-bottom:20px;
        }
        h4{
          font-size: 30px;
          line-height: 38px;
          margin-bottom: 20px;
        }
        p{
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .home-services{
    padding-top: 70px;
    overflow: hidden;
  }

  .home-featured-products{
    padding:70px 0;
    .headers{
      display:flex;
      justify-content:space-between;
      align-items:center;
      .heading{
        max-width: 700px;
      }
      .view-all{
        gap:10px;
        border-radius:30px;
        padding:5px 20px;
      }
    }

    .cards{
      padding-top:40px;
      .product-card{
        gap:20px;
        margin-bottom:30px;
        .card-img{
          width:150px;
          height:150px;
          object-fit:cover;
          flex:0.5;
        }
        .card-info{
          height:100%;
          h4{
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 15px;
          }
          p{
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .home-featured-services{
    height: 650px;
    .heading{
      color: #0CFFEE;
    }
    .subheading{
      color: white;
    }
    p{
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 30px;
    }

    .iam-solutions-card{
      width:200px;
      padding:25px 15px;
      right:0;
      bottom:20%;
      border-top-left-radius:30px;
      border-bottom-left-radius:30px;

      h4{
        font-size: 26px;
        line-height: 34px;
      }

      .arrow-right{
        position:relative;
        .icon-container{
          .arrow-right-icon{
            background:rgb(0, 175, 175);
            color:#fff;
            left:-35px;
            bottom:5px;
            width:28px;
            height:28px;
            padding:8px;
          }
        }
      }
    }
  }

  .home-resources {
    padding: 70px 0;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px){
  .home-banner{
    height: 700px;
    .scroll-icon{
      left: 50%;
      bottom: 7%;
      transform: translate(-50%, -0);
    }
    h4{
      font-size: 24px;
      line-height: 50px;
    }
    h1{
      font-size: 46px;
      line-height: 60px;
      margin-bottom: 30px;
    }
    .social-sec{
      gap: 25px;
      bottom: 7%;
      left: 5%;
      p{
        font-size: 19px;
        margin: 0;
      }
      span{
        font-size: 17px;
      }
    }
    .chat-icons{
      gap: 20px;
      position: absolute;
      right: 5%;
      bottom: 7%;
      span{
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
    }
  }

  .home-about{
    padding:70px 0;

    .heading-section{
      max-width: 900px;
      .para{
        font-size: 17px;
        line-height: 30px;
      }
    }

    .about-img{
      width:100%;
      height:100%;
      // height:480px;
      object-fit:cover;
      margin-top: 30px;
    }

    .cards{
      padding-top:30px;
      .about-card{
        padding:20px;
        min-height:240px;
        img{
          margin-bottom:20px;
        }
        h4{
          font-size: 27px;
          line-height: 33px;
          margin-bottom: 15px;
        }
        p{
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }

  .home-featured-products{
    padding:60px 0;
    .headers{
      display:flex;
      justify-content:space-between;
      align-items:center;
      .heading{
        max-width: 700px;
      }
      .view-all{
        gap:10px;
        border-radius:30px;
        padding:5px 20px;
      }
    }

    .cards{
      padding-top:40px;
      .product-card{
        gap:20px;
        margin-bottom:30px;
        .card-img{
          width:150px;
          height:150px;
          object-fit:cover;
          flex:0.5;
        }
        .card-info{
          height:100%;
          h4{
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 15px;
          }
          p{
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .home-featured-services{
    height: 650px;
    .heading{
      color: #0CFFEE;
    }
    .subheading{
      color: white;
    }
    p{
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 30px;
    }

    .iam-solutions-card{
      width:180px;
      padding:25px 15px;
      right:0;
      bottom:10%;
      border-top-left-radius:30px;
      border-bottom-left-radius:30px;

      h4{
        font-size: 22px;
        line-height: 30px;
      }

      .arrow-right{
        position:relative;
        .icon-container{
          .arrow-right-icon{
            background:rgb(0, 175, 175);
            color:#fff;
            left:-35px;
            bottom:5px;
            width:28px;
            height:28px;
            padding:8px;
          }
        }
      }
    }
  }

  .home-resources {
    padding: 60px 0;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px){
  .home-banner{
    height: 700px;
    .scroll-icon{
      left: 50%;
      bottom: 7%;
      transform: translate(-50%, -0);
    }
    h4{
      font-size: 24px;
      line-height: 50px;
    }
    h1{
      font-size: 46px;
      line-height: 60px;
      margin-bottom: 30px;
    }
    .social-sec{
      gap: 25px;
      bottom: 7%;
      left: 5%;
      p{
        font-size: 19px;
        margin: 0;
      }
      span{
        font-size: 17px;
      }
    }
    .chat-icons{
      gap: 20px;
      position: absolute;
      right: 5%;
      bottom: 7%;
      span{
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
    }
  }

  .home-about{
    padding:60px 0;

    .heading-section{
      max-width: 900px;
      .para{
        font-size: 17px;
        line-height: 30px;
      }
    }

    .about-img{
      width:100%;
      height:100%;
      // height:480px;
      object-fit:cover;
      margin-top: 30px;
    }

    .cards{
      padding-top:30px;
      .about-card{
        padding:20px;
        min-height:240px;
        img{
          margin-bottom:20px;
        }
        h4{
          font-size: 27px;
          line-height: 33px;
          margin-bottom: 15px;
        }
        p{
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }

  .home-services{
    padding-top: 60px;
    overflow: hidden;
    .home-service-slider{
      max-width: 600px;
      padding: 50px 10px;
      .line-content-slider{
        text-align: center;
        .icon{
          margin-bottom: 20px;
        }
        h4{
          font-size: 30px;
          line-height: 50px;
        }
        p{
          font-size: 18px;
          line-height: 26px;
        }
        span{
          font-size: 15px;
          line-height: 21.86px;
        }
      }
    }
  }

  .home-featured-products{
    padding:60px 0;
    .headers{
      display:flex;
      justify-content:space-between;
      align-items:center;
      .heading{
        max-width: 700px;
      }
      .view-all{
        gap:10px;
        border-radius:30px;
        padding:5px 20px;
      }
    }

    .cards{
      padding-top:40px;
      .product-card{
        gap:20px;
        //margin-bottom:230px;
        .card-img{
          width:150px;
          height:150px;
          object-fit:cover;
          flex:0.5;
        }
        .card-info{
          height:100%;
          h4{
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 15px;
          }
          p{
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .home-featured-services{
    height: 600px;
    .heading{
      color: #0CFFEE;
    }
    .subheading{
      color: white;
    }
    p{
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 30px;
    }

    .iam-solutions-card{
      display: none;

    }
  }

  .home-resources {
    padding: 60px 0;
  }
}

@media screen and (max-width: 575px){
  .home-banner{
    height: 932px;
    background-repeat: no-repeat !important;
    background-position: center;
    background-size: cover !important;
    background: url("../../../../assets/website-images/home/banner-mbl.png");
    padding-top: 150px;
    .container{
      height: auto !important;
      .row{
        height: auto !important;
      }
    }
    .scroll-icon{
      left: 50%;
      bottom: 7%;
      transform: translate(-50%, -0);
      display: none;
    }
    h4{
      font-size: 22px;
      line-height: 50px;
    }
    h1{
      font-size: 38px;
      line-height: 48px;
      margin-bottom: 30px;
    }
    .social-sec{
      gap: 25px;
      bottom: 7%;
      left: 5%;
      display: none;
      p{
        font-size: 19px;
        margin: 0;
      }
      span{
        font-size: 17px;
      }
    }
    .chat-icons{
      gap: 20px;
      position: absolute;
      right: 5%;
      bottom: 7%;
      display: none;
      span{
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
    }
  }

  .home-about{
    padding:50px 0;

    .heading-section{
      max-width: 900px;
      .para{
        font-size: 17px;
        line-height: 30px;
      }
    }

    .about-img{
      width:100%;
      height:100%;
      // height:480px;
      object-fit:cover;
      margin-top: 30px;
    }

    .cards{
      padding-top:30px;
      .about-card{
        padding:20px;
        min-height:240px;
        img{
          margin-bottom:20px;
        }
        h4{
          font-size: 27px;
          line-height: 33px;
          margin-bottom: 15px;
        }
        p{
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }

  .home-services{
    padding-top: 50px;
    overflow: hidden;
    .home-service-slider{
      max-width: 600px;
      padding: 40px 10px;
      .line-content-slider{
        text-align: center;
        .icon{
          margin-bottom: 20px;
        }
        h4{
          font-size: 27px;
          line-height: 40px;
        }
        p{
          font-size: 15px;
          line-height: 26px;
        }
        span{
          font-size: 15px;
          line-height: 21.86px;
        }
      }
    }
  }

  .home-featured-products{
    padding:50px 10px;
    .headers{
      display:flex;
      justify-content:space-between;
      align-items:center;
      .heading{
        max-width: 700px;
      }
      .view-all{
        gap:10px;
        border-radius:30px;
        padding:5px 20px;
      }
    }

    .cards{
      padding-top:40px;
      .product-card{
        gap:20px;
        margin-bottom:20px;
        .card-img{
          width:150px;
          height:150px;
          object-fit:cover;
          flex:0.5;
        }
        .card-info{
          height:100%;
          h4{
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 15px;
          }
          p{
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .home-featured-services{
    height: 550px;
    .heading{
      color: #0CFFEE;
    }
    .subheading{
      color: white;
    }
    p{
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 30px;
    }

    .iam-solutions-card{
      display: none;

    }
  }

  .home-resources {
    padding: 50px 0;
  }

}