.header{
  position: absolute;
  width: 100%;
  padding: 20px 0;
  z-index: 999;
  &.menu-open{
    background-color:rgba(3, 18, 18,0.9);
  }
  .logo{
    max-width: 220px;
    margin: 10px 0;
  }
  .menu-area{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu{
      display: flex;
      align-items: end;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      .top-menu{
        ul{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
          li{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            a{
              color: #FFFFFF;
            }
          }
        }
      }
      .main-menu{
        display: flex;
        gap: 30px;
        ul{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;
          li{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            a{
              color: #FFFFFF;
            }
          }
        }
        .options{
          //border-left: 1px solid white;
          display: flex;
          align-items: center;
          gap: 20px;
          padding: 0 15px;
          color: white;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }
}


.big-menu{
  background-color:white;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  //background-image: url('../../../../assets/website-images/Menu.png');
  background:linear-gradient(to top, white 0%, white 49%, #00BFB2 30%, #00BFB2 100%);
  background-size: cover;
  background-position: center;
  padding: 2%;
  .menu-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    .logo{
      max-width: 200px;
    }
    .close-icon{
      color: white;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .menu-content{
    width: 90%;
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 6%;

    .menu-list{
      ul{
        li{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 40px;
          letter-spacing: -1px;
          text-transform: capitalize;
          color: #FFFFFF;
          margin: 20px 0;
          cursor: pointer;
          a{
            color: #FFFFFF;
          }
        }
      }
    }
    .social-list{
      max-width: 300px;
      ul{
        li{
          margin: 35px 0;
          h4{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 20px;
            color: #00BFB2;
          }
          p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
          }
          .social-icons{
            display: flex;
            align-items: center;
            gap: 15px;
            span{
              height: 42px;
              width: 42px;
              background: #00BFB2;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 17px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.mega-menu {
  display: flex;
  width: 100%;
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;


}

.header-2{
  .nav-item{
    &.dropdown{
      position: inherit;
    }
  }

  .products-dropdown{
    width: 100%;
  }
}

.mega-menu-column {
  flex: 1;
  margin-right: 20px;
}

.mega-menu-column h5 {
  margin-bottom: 10px;
}

.mega-menu-column .dropdown-item {
  margin-bottom: 5px;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: white;
}