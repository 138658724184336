.mega-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  width: 100%;
  z-index: 1000;
  display: block;
  background-color: rgba(3, 18, 18, 1);
  border-top: 1px solid rgba(255, 255, 255, 0.1); // Optional border for styling
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 30px 0;

  .menu-list-box {
    text-align: left;

    h5 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 52px;
      line-height: 60px;
      letter-spacing: -1px;
      color: #FFFFFF;
      margin-bottom: 30px;
    }

    ul {
      display: flex;
      flex-direction: column !important;
      justify-content: flex-start !important;
      align-items: flex-start !important;

      li {
        text-align: left !important;

        a {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 28px;
          line-height: 36px;
          letter-spacing: -1px;
          color: #FFFFFF;
          text-align: left;
        }
      }
    }
  }
}

.mega-dropdown ul {
  padding-left: 0;
  list-style: none;
}

.mega-dropdown ul li {
  margin-bottom: 10px;
  cursor: pointer;

  a {
    text-decoration: none; // You can add more styles here if needed
  }
}

.mega-dropdown ul li:hover {
  color: #FFFFFF;
}

.nav-link {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.main-menu {
  .nav-item {
    .nav-link {
      a {
        color: #FFFFFF;
      }

      &:hover {
        color: #FFFFFF;
      }

      &:visited {
        color: #FFFFFF;
      }
    }
  }
}