.iam-page{
  p{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000000;
  }
  .section-1{
    padding: 70px 0;
  }
 .section-2{
   padding:  0 0 60px 0;
    h3{
      font-family: Poppins;
      font-size: 46px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -1px;
      text-align: left;
      margin-bottom: 30px;
    }
   .d-box{
     text-align: center;
     background: #F3FBFA;
     padding: 50px 20px;
     height: 430px;
     margin-bottom: 25px;
     h4{
       font-family: Poppins;
       font-size: 26px;
       font-weight: 600;
       line-height: 36px;
       letter-spacing: -0.02em;
       text-align: center;
       margin-bottom: 20px;
     }
     p{
       font-family: Poppins;
       font-size: 18px;
       font-weight: 400;
       line-height: 24px;
       text-align: center;
       margin-bottom: 30px;
     }
     img{
        height: 150px;
       max-width: 200px;
       margin-bottom: 30px;
     }
   }
 }

  .section-3{
    background: #F3FBFA;
    padding: 70px 0;
  }
}