@import "pickers";
@import "fonts2.scss";
ul{
  padding: 0;
  margin: 0;
  list-style: none;
  li{

  }
}


//cursor pointer
.cp{
  cursor: pointer;
}
.u-btn{
  background: #00BFB2;
  color: #000000;
  padding: 19px 10px 15px 17px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  border-radius: 40px;
  span{
    border: 1px solid black;
    padding: 7px 15px;
    border-radius: 20px;
    margin-left: 15px;
  }
  &:hover{
    background: #000000;
    color: white;
    span{
      border: 1px solid white;
    }
  }
}

.gradient-border{
  background: linear-gradient(107.87deg, #FDFF9F -12.01%, #D2A84E 97.1%, #D2A84E 97.11%);
  border-radius: 15px; /* Rounded corners */
  overflow: hidden;
  padding: 1px;
  margin-bottom: 15px;
}
.sub-heading{
  color: var(--font-color-dark-1);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .seeall{
    color: #24DFFE;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    cursor: pointer;
  }
}
.mt-30{
  margin-top: 30px;
}
.error-message{
  color: #ff4d4f;
  font-family: var(--font-family);
}
.modal-para{
  color: var(--grey, #A8A9BA);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.theme-dropdown{
  width: 200px;
  box-shadow: 0px 0px 2px 0px var(--theme-border);
  .ant-dropdown-menu{
    background-color: var(--card-color);
    .ant-dropdown-menu-item{
      margin: 10px 0;
      span{
        color: var(--font-color-dark-1);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
.dropdown-btn{
  border-radius: 8px;
  border: 1px solid rgba(245, 245, 245, 0.15);
  background: var(--input-background);
  padding: 5px 8px;
  display: flex;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  svg{
    font-size: 22px;
    color: var(--font-color-dark-1);
  }
  span{
    color: var(--font-color-dark-1);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }
}
.ant-dropdown-menu{
  min-width: 120px;
  .ant-dropdown-menu-item{
    border-radius: 0;
    border-bottom: 1px solid #353535;
    &:last-child{
      border-bottom: none;
    }
    .ant-dropdown-menu-title-content{
        span{

        }
      svg{
        margin-top: -5px;
      }
    }
  }
}
:where(.css-dev-only-do-not-override-zo25gf).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
  padding: 10px;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px var(--input-background) inset !important;
  background-color: var(--input-background) !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: var(--font-color-dark-1) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}
//input:-webkit-autofill {
//  background-color: transparent !important;
//  -webkit-box-shadow: 0 0 0 50px black inset;
//}

a{
  text-decoration: none;
}
.form-avatar{
  margin-bottom: 15px;
  .field-label{
    color: #E9E9E9;
     font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 10px;
    margin-bottom: 10px;
    .steric{
      color: var(--secondary-color);
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: none;
    }
  }
}

.btn-call{
  border-radius: 100px;
  background: #FF5A4E;
  color: white;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  border: 1px solid var(--g1, #FF5A4E);
  &:hover{
    color: #FF5A4E;
    border: 1px solid var(--g1, #FF5A4E);
    background: transparent;
    .prefix-icon{
      svg{
        path{
          fill: #FF5A4E;
          transition: 0.3s;
        }
      }
    }
  }
}

.btn-light{
  background: #D2A84E;
  color: var(--Bg, #0C1033);
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  border: 1px solid var(--g1, #D2A84E);
  &:hover{
    color: #D2A84E;
    border: 1px solid var(--g1, #D2A84E);
    background: transparent;
    .prefix-icon{
      svg{
        path{
          fill: #D2A84E;
          transition: 0.3s;
        }
      }
    }
  }
}


.btn-light-lg{
  background: #D2A84E;
  color: var(--Bg, #0C1033);
  font-family: Inter;
  font-weight: 500;
  padding: 12px 24px;
  font-style: normal;
  width: 100%;
  line-height: normal;
  border: 1px solid var(--g1, #D2A84E);
  &:hover{
    color: #D2A84E;
    border: 1px solid var(--g1, #D2A84E);
    background: transparent;
    .prefix-icon{
      svg{
        path{
          fill: #D2A84E;
          transition: 0.3s;
        }
      }
    }
  }
}
.btn-light-md{
  background:#D2A84E;
  color: var(--Bg, #0C1033);
  font-family: Inter;
  font-weight: 500;
  padding: 8px 22px;
  font-style: normal;
  line-height: normal;
  border: 1px solid var(--g1, #D2A84E);
  &:hover{
    color: #D2A84E;
    border: 1px solid var(--g1, #D2A84E);
    background: transparent;
    svg{
      path{
        fill: #D2A84E;
        transition: 0.3s;
      }
    }
    .prefix-icon{
      svg{
        path{
          fill: #D2A84E;
          transition: 0.3s;
        }
      }
    }
  }
}

.btn-circle-sm{
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
}
.social-connect-btn{
  border-radius: 100px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Bg, #0C1033);
  font-family: Inter;
  font-weight: 500;
  padding: 12px 24px;
  font-style: normal;
  width: 100%;
  line-height: normal;
  border: 1px solid var(--theme-border);
  margin-bottom: 20px;
  &:hover{
    color: var(--Bg, #0C1033);
    background: white;
    border: 1px solid var(--theme-border);
  }
}
.btn-counter{
  border-radius: 100px;
  background: var(--input-background);
  border: 1px solid var(--theme-border);
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  color: var(--font-color-dark-1);
  &:hover{
    background: var(--input-background);
    border: 1px solid var(--theme-border);
  }
  .prefix-icon{
    svg{
      path{
        fill: var(--font-color-dark-1);
        transition: 0.3s;
      }
    }
  }
  &.lg{
    padding: 12px 24px;
    font-weight: 500;
    width: 100%;
  }
}
.btn-dark{
  border-radius: 100px;
  background: #252851;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  &.md{
    padding: 8px 24px;
    font-weight: 500;
  }
  &.lg{
    padding: 12px 24px;
    font-weight: 500;
    width: 100%;
  }
  &.center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prefix-icon{
    svg{
      path{
        fill:white;
      }
    }
  }
}
.btn-outline{
  border-radius: 100px;
  border: 1px solid var(--g1, #38DFE4);
  color: var(--font-color-dark-1);
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  &:hover{
    background: var(--g1, linear-gradient(270deg, #38DFE4 0%, #01B0C3 100%));
    color: var(--Bg, #0C1033);
  }
}
.btn-outline-md{
  border-radius: 100px;
  border: 1px solid var(--g1, #38DFE4);
  color: var(--font-color-dark-1);
  padding: 8px 22px;
  font-family: Inter;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  .prefix-icon{
    svg{
      path{
        fill: var(--font-color-dark-1);
        transition: 0.3s;
      }
    }
  }
  &:hover{
    border: 1px solid var(--g1, #38DFE4);
    background: var(--g1, linear-gradient(270deg, #38DFE4 0%, #01B0C3 100%));
    color: var(--Bg, #0C1033);
    .prefix-icon{
      svg{
        path{
          fill: #0C1033;
          transition: 0.3s;
        }
      }
    }
  }
}
.btn-outline-lg{
  border-radius: 100px;
  padding: 12px 24px;
  font-style: normal;
  width: 100%;
  border: 1px solid var(--g1, #38DFE4);
  color: var(--font-color-dark-1);
  font-family: Inter;
  line-height: normal;
  font-weight: 500;
  .prefix-icon{
    svg{
      color: var(--font-color-dark-1);
      path{
        fill: var(--font-color-dark-1) ;
        transition: 0.3s;
      }
    }
  }
  &:hover{
    background: var(--g1, linear-gradient(270deg, #38DFE4 0%, #01B0C3 100%));
    color: var(--Bg, #0C1033);
    .prefix-icon{
      svg{
        color: var(--font-color-dark-1);
        path{
          fill: var(--font-color-dark-1) ;
          transition: 0.3s;
        }
      }
    }
  }
}
.btn-small{
  height: 32px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
.btn-icon-light{
  border-radius: 50%;
  background: var(--primary-color);
  color: var(--Bg, #0C1033);
  border: 1px solid var(--primary-color);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  &:hover{
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    background: transparent;
  }
}
.btn-light-modal{
  border-radius: 5px;
  background: #F95C59;
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  border: 1px solid #F95C59;
  padding: 9px 25px;
  &:hover{
    color: #F95C59;
    border: 1px solid #F95C59;
    background: transparent;
  }
}
.btn-text{
  color: var(--font-color-dark-1);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  &:active{
    box-shadow: none;
    outline: none;
    border: none;
    background: transparent;
  }
  &:hover{
    color: var(--primary-color);
    background: transparent;
  }
}
.btn.disabled, .btn:disabled{
  color: #97979759;
  pointer-events: none;
  background-color: #353535;
  background: #353535;
  border-color: #353535;
  opacity:0.8;

}
.number-input{
  .ant-input-number{
    &.ant-input-number-disabled{
      input{
        color: #BEBEBE !important;
      }
    }
  }
}



//radio
.ant-radio-wrapper{
  span{
    color: var(--font-color-dark-1);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &:hover{
    .ant-radio-inner {
      border-color: #212121;
    }
  }
  .ant-radio-checked{
    &:after{
      border: 1px solid #212121;
    }
    &:hover{
      .ant-radio-inner {
        border-color: #212121 !important;
      }
    }
    .ant-radio-inner {
      background-color: transparent;
      border-color: #212121 !important;
      &:after{
        transform: scale(1);
      }
    }
  }
}
.ant-radio{
  .ant-radio-inner{
    background: transparent !important;
    border-color:#97979759;
    width: 24px;
    height: 24px;
    &:after{
      background: #212121;
      //inset-block-start: -0.3px;
      //inset-inline-start: 0.1px;
      //width: 38px;
      //height: 38px;
      //border-radius: 50%;
      transform: translate(0px, 0px) !important;
    }
  }
}
//end radio

.dfields{
  margin: 15px 0;
}

.ant-spin{
  .ant-spin-dot-item{
    background: linear-gradient(180deg, #F5B640 0%, #E59B0C 49.48%, #E26528 100%);
  }
}


//Ant design dashboard view filter design
.dashboard-filter{
  display: flex;
  gap: 20px;
  align-items: center;
  &.full-width{
    .dfields{
      width: 100%;
    }
    .search-field{
      width: 100%;
    }
    .text-field{
      width: 100%;
      .ant-input-affix-wrapper{
        width: 100%;
        min-width: 100%;
      }
    }
  }
  .radio-filter{
    .ant-radio-group{
      .ant-radio-wrapper{
        span{
          &:nth-of-type(2){
            color: var(--font-color-dark-1);
          }
        }
      }
    }
  }
  .filter-heading{
    flex: 1 1;
    h2{
      color: var(--font-color-dark-1);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 133.333% */
      margin: 0;
    }
  }
  .text-field .ant-input-affix-wrapper{
    border-radius: 5px;
    padding: 4px 10px;
    min-width: 400px;
  }
  .btn-light-lg{
    padding: 7px 14px;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    .prefix-icon{
      margin: 0 8px 0 0;
      font-size: 20px;
      line-height: 10px;
    }
  }
}
//Ant design dashboard view filter design


.profile-content{
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  margin-bottom: 15px;
  .profile-image{

  }
  .profile-details{
    width: 100%;
    h3{
      color: var(--font-color-dark-1);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      margin-bottom: 3px;
      display: flex;
      cursor: pointer;
      .tick{
        margin-left: 5px;
      }
      .option{
        margin-left: auto;
        font-size: 20px;
      }
    }
    p{
      color: var(--font-color-dark-1);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 200% */
      margin: 0;
    }
  }
}

.uploader{
  label{
    color: #A8A9BA;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
  .ant-upload-drag-icon{
    font-size: 30px;
    color: white;
  }
  .ant-upload-wrapper{
    .ant-upload-drag {
      .ant-upload-hint{
        color: white;
      }
    }
  }
}
:where(.css-dev-only-do-not-override-1835baa).ant-checkbox .ant-checkbox-inner:after {
  inset-inline-start: 29%;
  width: 5.857143px;
  height: 9.571429px;
}
.ant-checkbox-group{
  //flex-direction: column;

  .ant-checkbox-wrapper{
    color: var(--font-color-dark-1);
    font-family: Inter;
    //font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 22px;
    padding: 15px 30px;
    background-color: #303030;
    .ant-checkbox{
      .ant-checkbox-inner{
        border-color: #97979759;
      }
      &:hover{
        .ant-checkbox-inner{
          border-color:#97979759;
        }
      }
      &.ant-checkbox-checked{
        .ant-checkbox-inner{
          background:#212121;
          border-color: transparent;
          &:after{
            border-color:white;
            top: 45%;
          }
        }
      }
    }
  }
}
.ant-checkbox-wrapper{
  color: var(--font-color-dark-1);
  font-family: Inter;
  //font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 22px;
  padding: 8px 0;
  .ant-checkbox{
    &:hover{
      .ant-checkbox-inner{
        border-color:var(--checkbox-border);
      }
    }
    &.ant-checkbox-checked{
      .ant-checkbox-inner{
        background: var(--g1, linear-gradient(270deg, #38DFE4 0%, #01B0C3 100%));
        border-color: transparent;
        &:after{
          border-color:var(--checkbox-border);
          top: 45%;
        }
      }
    }
  }
}


// music player

.music-player-main{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 999;
  background: var(--background);
  padding: 10px 5%;
  .music-detail{
    display: flex;
    align-items: center;
    flex: 1 1 400px;
    gap: 20px;
    .music-info{
      h4{
        color: var(--font-color-dark-1);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.9px;
      }
      p{
        color: #B3B3B3;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.7px;
        margin-bottom: 0;
      }
    }

  }

  .rhap_container{
    background: var(--background);
    border-radius: 10px;
    box-shadow: none;
    .rhap_main-controls{
      button{
        svg{
          path{
            fill:var(--font-color-dark-1)
          }
        }
      }
    }
    .rhap_play-pause-button{
      width: 50px;
      height: 50px;
    }
    .rhap_current-time{
      color: #C4C4C4;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.56px;
    }
    .rhap_volume-container{
      button{
        svg{
          path{
            fill:var(--font-color-dark-1)
          }
        }
      }
      .rhap_volume-bar{
        .rhap_volume-indicator{
          background: linear-gradient(180deg, #F5B640 0%, #E59B0C 49.48%, #E26528 100%);
        }
      }
    }
    .rhap_total-time{
      color: #C4C4C4;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.56px;
    }
    .rhap_repeat-button{
      //color: white;
      font-size: 22px;
    }
    .rhap_volume-button{
      color: white;
    }
    .rhap_progress-section{
      margin-top: 15px;
      .rhap_progress-bar{
        .rhap_download-progress{
          background-color: #6F6F6F;
        }
        .rhap_progress-filled{
          background: linear-gradient(180deg, #F5B640 0%, #E59B0C 49.48%, #E26528 100%);
        }
        .rhap_progress-indicator{
          background: linear-gradient(180deg, #F5B640 0%, #E59B0C 49.48%, #E26528 100%);
        }
      }
    }
  }

  .music-options{
    display: flex;
    align-items: center;
    gap: 40px;
    margin-left: 40px;
    span{
      cursor: pointer;
    }
  }

  .close{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    span{
      color: white;
      font-size: 18px;
      svg{
        color:var(--font-color-dark-1);
      }
    }
  }
}

// modal search
.modal-search{
  display: flex;
  margin-top: 15px;
  gap: 10px;
  .search-field{
    flex: 1;
    .text-field .ant-input-affix-wrapper{
      border-radius: 10px;
    }
  }
  .btn-light-md{
    border-radius: 10px;
    padding: 10px;
    font-size: 22px;
  }
}

.dfields{
  &.required{
    .field-label{
      color: #A8A9BA;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 10px;
      .steric{
        color: var(--Y1, #E7B300);
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: inline-block;
      }
    }
  }
  .field-label{
    .steric{
      display: none;
    }
  }
}


// Tour
.ant-tour{
  width: inherit;
  .ant-tour-content{
    max-width: 350px;
    .ant-tour-inner{
      padding: 0 10px;
    }
    .ant-tour-header{
      padding-bottom: 0;
      .ant-tour-title{
        color: var(--Black, #000);
        font-variant-numeric: lining-nums tabular-nums;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 225% */
      }
    }
    .ant-tour-description{
      color: #7E7E7E;
    }
    .ant-tour-footer{
      flex-direction: row-reverse;
      .ant-tour-buttons{
        margin-inline-end: auto;
        margin-inline-start: 0;
        margin-top: 6px;
        padding-bottom: 10px;
        .ant-btn{
          margin-inline-start: 0;
          margin-inline-end: 8px;
          &.ant-tour-prev-btn{
            display: none;
          }
          &.ant-tour-next-btn{
            border-radius: 5px;
            background: var(--Grad-1, linear-gradient(270deg, #38DFE4 0%, #01B0C3 100%));
            color: var(--Bg, #0C1033);
            padding: 5px 15px;
            display: flex;
            align-items: center;
            box-shadow: none;
            border: none;
            font-weight: 500;
            font-size: 13px;
          }
        }
      }
      .ant-tour-indicators{
        margin-top: 6px;
        span{
          color: #BABABA;
        }
      }
    }
  }
}


// image uplaoder

.product-image-uploader{
  display: flex;
  flex-direction: column;
  .field-label{
    color: #A8A9BA;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    .steric{
      color: var(--Y1, #E7B300);
      font-family: Inter;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .ant-upload{
    width: 100%;
  }
  .ant-upload-list{
    display: flex;
    align-items: center;
    gap: 2%;
    row-gap: 16px;
    flex-wrap: wrap;
    &:before{
      display: none;
    }
    .ant-upload-list-item-container{
      width: 23.5%;
      height: 90px;
      .ant-upload-list-item{
        border: 1px dashed var(--theme-border);
        background: var(--input-background);
        //height: 102px;
        //width: 20%;
        width: 100%;
        height: 90px;
        .ant-upload-list-item-name{
          display: none;
        }
        .ant-upload-list-item-thumbnail{
          height: 75px;
          width: 100%;
          img{
            object-fit: cover;
            height: 75px;
            width: 100%;
          }
        }

        .ant-upload-list-item-actions{
          position: absolute;
          background: white;
          border-radius: 50%;
          top: -8px;
          right: -9px;
          .anticon{
            color: #ff4d4f;
          }
          .ant-btn-icon{
            color: #ff4d4f;
          }
        }
      }
    }
  }
  .upload-box{
    border-radius: 10px;
    border: 1px dashed var(--theme-border);
    background: var(--input-background);
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;
    .ant-upload-drag-icon{
      color: #797979;
      font-size: 30px;
      line-height: 14px;
    }
    .ant-upload-hint{
      color: rgba(233, 233, 233, 0.93);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

//Chips
.chips{
  span{
    color: white;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 3px 12px;
    border-radius: 20px;
    cursor: pointer;
    &.warning{
      background: rgba(231, 179, 0, 0.75);
    }
    &.success{
      background: rgba(55, 215, 168, 0.70);
    }
    &.danger{
      background: rgba(185, 17, 5, 0.80);
    }
    &.success1{
      background: #498B3D;
    }
    &.danger1{
      background: rgba(255, 90, 78, 0.80);
    }
  }
}

//search list normal
.search-list-normal{
  .filter-select .text-field .ant-input-affix-wrapper {
    border-radius: 8px;
    padding: 7px 8px;
  }
  ul{
    li{
      margin-bottom: 10px;
      span{
        color: var(--font-color-dark-1);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.7px;
      }
    }
  }
}

//responsive
//@media (min-width: 1400px) {
//  .container{
//    max-width: 1400px;
//  }
//}
//
//@media (min-width: 2000px) {
//  .container{
//    max-width: 1600px;
//  }
//}

@media screen and (min-width: 1900px) {
  .container {
    max-width: 1650px;
  }
}

.chart-legend {
  margin-top: 20px; // Adjust the margin as needed
}


form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.radio-list-vertical{
  .ant-radio-group{
    display: flex;
    flex-direction: column;
    .ant-radio-wrapper{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      border-bottom: 1px solid var(--theme-border);
      &:last-child{
        border-bottom: none;
      }
      span{
        &:nth-of-type(2){
          width: 100%;
          padding: 17px 0;
        }
      }
    }
  }
}


.field-note{
  color: var(--font-color-grey-1);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-picker-dropdown{
  z-index: 9999;
}



