.tochless{
  p{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000000;
  }
  .section-1{
    padding: 70px 0;
    h3{
      font-family: Poppins;
      font-size: 56px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -1px;
      text-align: left;
      margin-bottom: 30px;
    }
    h5{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
      margin-bottom: 30px;
    }
    p{
      font-family: Poppins;
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;

    }
    img{
      max-width: 500px;
    }
  }

  .section-2{
    background: #C4EAE8;
    padding: 80px 0;
    h2{
      margin-bottom: 50px;
    }
    .sec2-box{
      display: flex;
      align-items: center;
      gap: 40px;
      background: #E7F7F5;
      border: 1px solid #00BFB2;
      border-radius: 20px;
      padding: 60px 40px;
      .img{

      }
      .content{
        h4{
          font-family: Poppins;
          font-size: 32px;
          font-weight: 600;
          line-height: 36px;
          letter-spacing: -0.02em;
          text-align: left;
        }
        p{
          font-family: Poppins;
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          text-align: left;
        }
      }
    }
  }

  .home-featured-products{
    padding:80px 0;
    text-align:left;
    h2{
      margin-bottom: 50px;
    }

    .cards{
      padding-top:40px;
      .product-card{
        display:flex;
        flex-wrap:wrap;
        gap:20px;
        align-items:center;
        margin-bottom:40px;
        .card-img{
          // max-width:300px;
          width:100%;
          height:100%;
          object-fit:cover;
          flex:0.5;
        }

        .card-info{
          // flex:0.5;
          display:flex;
          flex-direction:column;
          justify-content: center;
          height:100%;
          h4{
            font-family: Poppins;
            font-size: 26px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #000000;
            margin-bottom: 15px;
          }
          p{
            font-family: Poppins;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #000000;
          }
        }
      }
    }
  }

  .section-4{
    padding:  70px 0;
    background: #c4eae8;
    h2{
      text-align: center;
      max-width: 800px;
      margin: auto;
      margin-bottom: 60px;
    }
    .d-box{
      text-align: left;
      padding: 50px 20px;
      height: 430px;
      margin-bottom: 35px;
      h4{
        font-family: Poppins;
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;
        letter-spacing: -2px;
        text-align: left;
        margin-bottom: 20px;
      }
      p{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
      }
      img{
        height: 150px;
        max-width: 200px;
        margin-bottom: 30px;
      }
    }
  }

  .section-5{
    padding: 70px;
    h2{
      max-width: 600px;
    }
    ul{
      li{
        padding: 4% 0;
        border-bottom: 1px solid #D9D9D9;
        .sec5-box{
          display: flex;
          align-items: center;
          gap: 6%;
          img{
            max-width: 300px;
          }
          .content{
            h4{
              font-family: Poppins;
              font-size: 32px;
              font-weight: 600;
              line-height: 36px;
              letter-spacing: -0.02em;
              text-align: left;
              margin-bottom: 30px;
            }
            p{
              font-family: Poppins;
              font-size: 18px;
              font-weight: 400;
              line-height: 30px;
              text-align: left;
            }
          }
        }
      }
    }
  }

  .section-6{
    background: #f3fbfa;
    padding: 70px;
    .heading-section{
      max-width: 700px;
      margin: auto;
      text-align: center;
      margin-bottom: 30px;
    }

    .sec6-box{
      text-align: center;
      margin: 25px 0;
      img{
        margin-bottom: 20px;
        max-width: 193px;
      }
      h4{
        font-family: Poppins;
        font-size: 32px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.02em;
        text-align: center;
        margin-bottom: 20px;
      }
      p{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
      }
    }

  }

  .product-section{
    h5{
      font-family: Poppins;
      font-size: 28px;
      font-weight: 600;
      line-height: 40px;
      color: black;
    }
  }

}