@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-ExtraBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-ExtraBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro ExBold';
  src: url('../fonts2/CerebriSansPro-ExtraBoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-ExtraBoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro ExLight';
  src: url('../fonts2/CerebriSansPro-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-HeavyItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-HeavyItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}


@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro SmBold';
  src: url('../fonts2/CerebriSansPro-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Cerebri Sans Pro';
  src: url('../fonts2/CerebriSansPro-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: block;
}



