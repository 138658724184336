.page-banner{
  height: 700px;
  //background-image: url('../../../../assets/website-images/page-banner.png');
  display: flex;
  align-items: center;
  padding-top: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 62px;
    line-height: 70px;
    letter-spacing: -1px;
    //text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
}


@media screen and (min-width: 1600px) and (max-width: 1800px){
  .page-banner{
    height: 680px;
    padding-top: 90px;
    h1{
      font-weight: 600;
      font-size: 62px;
      line-height: 70px;
      margin-bottom: 30px;
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px){
  .page-banner{
    height: 680px;
    padding-top: 80px;
    h1{
      font-weight: 600;
      font-size: 58px;
      line-height: 68px;
      margin-bottom: 30px;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
  .page-banner{
    height: 650px;
    padding-top: 80px;
    h1{
      font-weight: 600;
      font-size: 54px;
      line-height: 62px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
  .page-banner{
    height: 600px;
    padding-top: 70px;
    img{
      max-width: 320px;
    }
    h1{
      font-weight: 600;
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px){
  .page-banner{
    height: auto;
    padding: 80px 0;
    h1{
      font-weight: 600;
      font-size: 44px;
      line-height: 56px;
      margin-bottom: 20px;
      margin-top: 90px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px){
  .page-banner{
    height: auto;
    padding: 80px 0;
    h1{
      font-weight: 600;
      font-size: 42px;
      line-height: 52px;
      margin-bottom: 20px;
      margin-top: 90px;
    }
  }
}

@media screen and (max-width: 575px){
  .page-banner{
    height: auto;
    padding: 80px 0;
    h1{
      font-weight: 600;
      font-size: 38px;
      line-height: 48px;
      margin-bottom: 20px;
      margin-top: 90px;
    }
  }
}