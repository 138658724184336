.contact{
    text-align:left;
    padding:50px 0;
    background:#00BFB233;
    .heading{
        font-family: Poppins;
        font-size: 42px;
        font-weight: 600;
        line-height: 46px;
        text-align: left;
        color: black;
    }
    .contact-para{
        // margin-left:70px;
        padding-left:20px;
        border-left:2px solid rgba(0,0,0,0.5);
        max-width:450px;
        font-family: Poppins;
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: -0.02em;
        text-align: left;

    }
    .mobile-container{
        height:200px;
        .mobile-icon{
            width: 400px;
            /* height: 400px; */
            object-fit: cover;
            position: relative;
            bottom: 94px;
        }
    }

    .store-img{
        max-width: 200px;
    }
}

@media screen and (min-width: 1600px) and (max-width: 1800px){
    .contact{
        padding:50px 0;
        .heading{
            font-size: 38px;
            line-height: 46px;
        }
        .contact-para{
            padding-left:20px;
            border-left:2px solid rgba(0,0,0,0.5);
            max-width:450px;
            font-size: 17px;
            line-height: 26px;
        }
        .mobile-container{
            height:200px;
            .mobile-icon{
                width: 400px;
                object-fit: cover;
                bottom: 94px;
            }
        }
        .store-img{
            max-width: 200px;
        }
    }
}

@media screen and (min-width: 1400px) and (max-width: 1599px){
    .contact{
        padding:50px 0;
        .heading{
            font-size: 38px;
            line-height: 46px;
        }
        .contact-para{
            padding-left:20px;
            border-left:2px solid rgba(0,0,0,0.5);
            max-width:450px;
            font-size: 17px;
            line-height: 26px;
        }
        .mobile-container{
            height:200px;
            .mobile-icon{
                width: 400px;
                object-fit: cover;
                bottom: 94px;
            }
        }
        .store-img{
            max-width: 200px;
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
    .contact{
        padding:40px 0;
        .heading{
            font-size: 38px;
            line-height: 46px;
        }
        .contact-para{
            padding-left:20px;
            border-left:2px solid rgba(0,0,0,0.5);
            max-width:450px;
            font-size: 17px;
            line-height: 26px;
        }
        .mobile-container{
            height:200px;
            .mobile-icon{
                width: 400px;
                object-fit: cover;
                bottom: 94px;
            }
        }
        .store-img{
            max-width: 200px;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .contact{
        padding:70px 0;
        .heading{
            font-size: 38px;
            line-height: 46px;
        }
        .contact-para{
            padding-left:20px;
            border-left:2px solid rgba(0,0,0,0.5);
            max-width:450px;
            font-size: 17px;
            line-height: 26px;
        }
        .mobile-container{
            height:200px;
            .mobile-icon{
                width: 400px;
                object-fit: cover;
                bottom: 107px;
            }
        }
        .store-img{
            max-width: 200px;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .contact{
        padding:70px 0;
        .heading{
            font-size: 38px;
            line-height: 46px;
        }
        .contact-para{
            padding-left:20px;
            border-left:2px solid rgba(0,0,0,0.5);
            max-width:450px;
            font-size: 17px;
            line-height: 26px;
        }
        .mobile-container{
            height:200px;
            .mobile-icon{
                width: 400px;
                object-fit: cover;
                bottom: 107px;
            }
        }
        .store-img{
            max-width: 200px;
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 767px){
    .contact{
        padding:60px 0;
        .heading{
            font-size: 36px;
            line-height: 42px;
        }
        .contact-para{
            padding-left:20px;
            border-left:2px solid rgba(0,0,0,0.5);
            max-width:450px;
            font-size: 17px;
            line-height: 26px;
        }
        .mobile-container{
            height:200px;
            .mobile-icon{
                width: 400px;
                object-fit: cover;
                bottom: 107px;
            }
        }
        .store-img{
            max-width: 200px;
        }
    }
}

@media screen and (max-width: 575px){
    .contact{
        padding:60px 0;
        .heading{
            font-size: 36px;
            line-height: 42px;
        }
        .contact-para{
            padding-left:20px;
            border-left:2px solid rgba(0,0,0,0.5);
            max-width:450px;
            font-size: 17px;
            line-height: 26px;
        }
        .mobile-container{
            height:200px;
            .mobile-icon{
                width: 400px;
                object-fit: cover;
                bottom: 107px;
            }
        }
        .store-img{
            max-width: 200px;
        }
    }
}