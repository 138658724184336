.heading{
  font-family: 'Poppins';
  font-size: 54px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -2px;
}

.subheading{
  font-family: Poppins;
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 10px;
  color: #00BFB2;
}

@media screen and (min-width: 1600px) and (max-width: 1800px){
  .heading{
    font-size: 52px;
    font-weight: 600;
    line-height: 60px;
  }

  .subheading{
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px){
  .heading{
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
  }

  .subheading{
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
  .heading{
    font-size: 46px;
    font-weight: 600;
    line-height: 60px;
  }

  .subheading{
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
  .heading{
    font-size: 44px;
    font-weight: 600;
    line-height: 60px;
  }

  .subheading{
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px){
  .heading{
    font-size: 42px;
    font-weight: 600;
    line-height: 60px;
  }

  .subheading{
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px){
  .heading{
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
  }

  .subheading{
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 575px){
  .heading{
    font-size: 35px;
    font-weight: 600;
    line-height: 42px;
  }

  .subheading{
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
  }
}