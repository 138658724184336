.policy{
  .setting-heading{
    font-family: var(--font-family);
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: var(--font-color-dark-1);
  }
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #F4F6F7;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ccc;
    border-radius: 10px;
  }
  p{
    color:var(--font-color-dark-1);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 25px;
  }
  h4{
    color:var(--font-color-dark-1);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}