.product-civil{
  background: #C4EAE8;
  padding: 90px 0;
  .heading-section{
    max-width:  700px;
    margin: auto;
    text-align: center;
    margin-bottom: 40px;
  }
  .civil-box{
    padding: 5%;
    border-radius: 10px;
    background-color: white;
    color: black;
    min-height: 670px;
    &.box-1{
      background-color: #00BFB2;
      color: white;
    }
    .img-box{
      text-align: right;
      margin-bottom: 10px;
      img{
        border: 5px solid #00BFB2 ;
        background: white;
        border-radius: 50%;
        padding: 20px;
        height: 200px;
        width: 200px;
        object-fit: contain;
      }
    }
    h4{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: -0.02em;
      margin-bottom: 20px;
    }
    p{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.product-section{
  padding: 120px 10px;
  background: white;
  &.reverse{
    background: #f3fbfa;
  }
  h4{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 20px;
  }
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 30px;
    color: #000000;
  }
  img{
    max-width: 600px;
  }
}

.product-section-2{
  padding: 50px 10px;
  background: #CCF2F0;
  h4{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 20px;
  }
  p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 50px;
    color: #000000;
    margin-bottom: 20px;
  }
  img{
    max-width:300px;
  }
}


@media screen and (min-width: 1600px) and (max-width: 1800px){
  .product-civil{
    padding: 90px 0;
    .heading-section{
      max-width:  700px;
      margin: auto;
      margin-bottom: 40px;
    }
    .civil-box{
      padding: 5%;
      border-radius: 10px;
      &.box-1{
      }
      .img-box{
        margin-bottom: 10px;
        img{
          border: 5px solid #00BFB2 ;
          padding: 20px;
          height: 200px;
          width: 200px;
        }
      }
      h4{
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 20px;
      }
      p{
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .product-section{
    padding: 120px 10px;
    &.reverse{

    }
    h4{
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 20px;
    }
    p{
      font-size: 19px;
      line-height: 30px;
    }
    img{
      max-width: 600px;
    }
  }

  .product-section-2{
    padding: 50px 10px;
    h4{
      font-size: 36px;
      line-height: 36px;
      margin-bottom: 20px;
    }
    p{
      font-size: 30px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    img{
      max-width:300px;
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px){
  .product-civil{
    padding: 80px 0;
    .heading-section{
      max-width:  700px;
      margin: auto;
      margin-bottom: 30px;
    }
    .civil-box{
      padding: 4%;
      border-radius: 10px;
      &.box-1{
      }
      .img-box{
        margin-bottom: 10px;
        img{
          border: 5px solid #00BFB2 ;
          padding: 16px;
          height: 180px;
          width: 180px;
        }
      }
      h4{
        font-size: 34px;
        line-height: 32px;
        margin-bottom: 20px;
      }
      p{
        font-size: 19px;
        line-height: 30px;
      }
    }
  }

  .product-section{
    padding: 100px 10px;
    &.reverse{

    }
    h4{
      font-size: 35px;
      line-height: 34px;
      margin-bottom: 20px;
    }
    p{
      font-size: 19px;
      line-height: 30px;
    }
    img{
      max-width: 500px;
    }
  }

  .product-section-2{
    padding: 50px 10px;
    h4{
      font-size: 34px;
      line-height: 32px;
      margin-bottom: 20px;
    }
    p{
      font-size: 27px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    img{
      max-width:300px;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
  .product-civil{
    padding: 70px 0;
    .heading-section{
      max-width:  700px;
      margin: auto;
      margin-bottom: 30px;
    }
    .civil-box{
      padding: 4%;
      border-radius: 10px;
      &.box-1{
      }
      .img-box{
        margin-bottom: 10px;
        img{
          border: 5px solid #00BFB2 ;
          padding: 13px;
          height: 160px;
          width: 160px;
        }
      }
      h4{
        font-size: 32px;
        line-height: 30px;
        margin-bottom: 20px;
      }
      p{
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .product-section{
    padding: 90px 10px;
    &.reverse{

    }
    h4{
      font-size: 33px;
      line-height: 32px;
      margin-bottom: 15px;
    }
    p{
      font-size: 18px;
      line-height: 26px;
    }
    img{
      max-width: 420px;
      max-height: 350px;
    }
  }

  .product-section-2{
    padding: 50px 10px;
    h4{
      font-size: 32px;
      line-height: 46px;
      margin-bottom: 20px;
    }
    p{
      font-size: 23px;
      line-height: 40px;
      margin-bottom: 20px;
    }
    img{
      max-width:300px;
      max-height: 400px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
  .product-civil{
    padding: 70px 0;
    .heading-section{
      max-width:  700px;
      margin: auto;
      margin-bottom: 30px;
    }
    .civil-box{
      padding: 3%;
      border-radius: 10px;
      &.box-1{
      }
      .img-box{
        margin-bottom: 10px;
        img{
          border: 4px solid #00BFB2 ;
          padding: 10px;
          height: 160px;
          width: 160px;
        }
      }
      h4{
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 20px;
      }
      p{
        font-size: 17px;
        line-height: 24px;
      }
    }
  }

  .product-section{
    padding: 80px 10px;
    &.reverse{

    }
    h4{
      font-size: 30px;
      line-height: 32px;
      margin-bottom: 15px;
    }
    p{
      font-size: 17px;
      line-height: 26px;
    }
    img{
      max-width: 380px;
      max-height: 320px;
    }
  }

  .product-section-2{
    padding: 50px 10px;
    h4{
      font-size: 30px;
      line-height: 44px;
      margin-bottom: 20px;
    }
    p{
      font-size: 22px;
      line-height: 38px;
      margin-bottom: 20px;
    }
    img{
      max-width:280px;
      max-height: 360px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px){
  .product-civil{
    padding: 70px 0;
    .heading-section{
      max-width:  700px;
      margin: auto;
      margin-bottom: 30px;
    }
    .civil-box{
      padding: 3%;
      border-radius: 10px;
      &.box-1{
        margin-bottom: 30px;
      }
      .img-box{
        margin-bottom: 10px;
        img{
          border: 4px solid #00BFB2 ;
          padding: 10px;
          height: 160px;
          width: 160px;
        }
      }
      h4{
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 20px;
      }
      p{
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .product-section{
    padding: 80px 10px;
    &.reverse{

    }
    h4{
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 15px;
    }
    p{
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 60px;
    }
    img{
      max-width: 380px;
      max-height: 380px;
    }
  }

  .product-section-2{
    padding: 60px 10px;
    h4{
      font-size: 30px;
      line-height: 44px;
      margin-bottom: 20px;
    }
    p{
      font-size: 22px;
      line-height: 38px;
      margin-bottom: 20px;
    }
    img{
      margin-top: 60px;
      max-width:280px;
      max-height: 360px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px){
  .product-civil{
    padding: 70px 0;
    .heading-section{
      max-width:  700px;
      margin: auto;
      margin-bottom: 30px;
    }
    .civil-box{
      padding: 3%;
      border-radius: 10px;
      &.box-1{
        margin-bottom: 30px;
      }
      .img-box{
        margin-bottom: 10px;
        img{
          border: 4px solid #00BFB2 ;
          padding: 10px;
          height: 160px;
          width: 160px;
        }
      }
      h4{
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 20px;
      }
      p{
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  .product-section{
    padding: 80px 10px;
    &.reverse{

    }
    h4{
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 15px;
    }
    p{
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 60px;
    }
    img{
      max-width: 380px;
      max-height: 380px;
    }
  }

  .product-section-2{
    padding: 60px 10px;
    h4{
      font-size: 30px;
      line-height: 44px;
      margin-bottom: 20px;
    }
    p{
      font-size: 22px;
      line-height: 38px;
      margin-bottom: 20px;
    }
    img{
      margin-top: 60px;
      max-width:280px;
      max-height: 360px;
    }
  }
}

@media screen and (max-width: 575px){
  .product-civil{
    padding: 50px 0;
    .heading-section{
      max-width:  700px;
      margin: auto;
      margin-bottom: 30px;
    }
    .civil-box{
      padding: 7% 3%;
      border-radius: 10px;
      &.box-1{
        margin-bottom: 30px;
      }
      .img-box{
        margin-bottom: 10px;
        img{
          border: 3px solid #00BFB2 ;
          padding: 5px;
          height: 120px;
          width: 120px;
        }
      }
      h4{
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 15px;
      }
      p{
        font-size: 15px;
        line-height: 22px;
      }
    }
  }

  .product-section{
    padding: 60px 10px;
    &.reverse{

    }
    h4{
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 15px;
    }
    p{
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 40px;
    }
    img{
      max-width: 300px;
      max-height: 300px;
    }
  }

  .product-section-2{
    padding: 50px 10px;
    h4{
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 20px;
    }
    p{
      font-size: 20px;
      line-height: 34px;
      margin-bottom: 20px;
    }
    img{
      margin-top: 60px;
      max-width:260px;
      max-height: 340px;
    }
  }
}