.services{
  p{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #000000;
  }

  .section-3{
    background: white;
    padding: 90px 0;
    &:nth-child(odd){
      background: #F3FBFA;
    }
    .heading{
      font-size: 44px;
    }
  }
}